import styled from "styled-components";

export const InputStyle = styled.div`
  /* TOP */

  .input-top {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .input-top-label {
    flex: auto;
    /*  */
    font-weight: 300;
    font-size: 14px;
  }
  .input-top-tooltip {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 8px;
  }

  /* BODY */

  .input-body {
    position: relative;
    display: flex;
    padding: 12px 16px;
    border: 1px solid ${(props) => props.theme.bn.gray_70};
    width: 100%;
    margin-bottom: 4px;
    border-radius: 3px;
    transition: 0.3s ease-in-out all;
    &:hover {
      border: 1px solid ${(props) => props.theme.bn.white};
    }
  }
  .input-body-wrapper {
    width: 100%;
    position: relative;
    display: flex;
  }
  .input-body-container {
    display: flex;
  }

  input {
    color: ${(props) => props.theme.bn.white};
    flex: auto;
    /*  */
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
  }
  textarea {
    color: ${(props) => props.theme.bn.white};
    background: none;
    border: none;
    resize: none;
    width: 100%;
    /*  */
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    &:focus  {
      outline: none;
    }
  }

  .input-body-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 8px;
    &.left {
      margin-left: 0px;
      margin-right: 8px;
    }
    .arrow-select {
      transform: rotate(0deg);
    }
  }
  /* BOTTOM */

  .input-bottom {
    /* visibility: hidden;
    opacity: 0; */
    /*  */
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    min-height: 16px;
  }
  .input-bottom-validation {
    display: flex;
    &__icon {
      margin-right: 8px;
    }
    &__text {
      color: red;
    }
  }

  &.focus {
    .input-body {
      border: 1px solid ${(props) => props.theme.bn.white};
    }
  }

  &.error {
    .input-body {
      border: 1px solid red;
    }
    .input-bottom-validation {
      color: red;
    }
  }
  &.success {
    .input-body {
      border: 1px solid green;
    }
    .input-bottom-validation {
      color: green;
    }
  }

  /* Sizes */

  &.small  {
    .input-top-label {
      font-size: 14px;
    }
    .input-body {
      padding: 8px 16px;
    }
    input {
      font-size: 14px;
      line-height: 14px;
    }
  }
  &.medium {
    .input-body {
      padding: 12px 16px;
    }
    input {
      font-size: 16px;
      line-height: 16px;
    }
  }
  &.large {
    .input-body {
      padding: 16px 16px;
    }
    input {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &.hide-bottom {
    .input-bottom {
      display: none;
    }
  }

  /* Text Editor */

  &.in-text-editor {
    .input-body {
      margin: 0;
      height: 48px;
      width: 114px;

      input {
        flex: initial;
        width: 100%;
      }
    }

    .input-bottom {
      display: none;
    }
  }
`;

export const SelectStyle = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  /* padding: 8px; */
  opacity: 0;
  visibility: hidden;
  z-index: 5;
  overflow-y: auto;

  /*  */

  background: ${(props) => props.theme.bn.black};
  box-shadow: 0px 4px 10px rgba(202, 209, 216, 0.4);
  border-radius: 8px;
  max-height: 200px;
  &.open {
    opacity: 1;
    visibility: visible;
  }

  .select-item {
    padding: 8px 12px;
    transition: 0.3s ease-in-out;
    /*  */
    margin-bottom: 4px;
    background: ${(props) => props.theme.bn.black};
    cursor: pointer;
    &:hover {
      background: ${(props) => props.theme.bn.gray_70};
    }
  }
  .selected-container {
    border: 1px solid ${(props) => props.theme.bn.gray_70};
  }

  .custom-item {
    padding: 4px 6px;
    p {
      padding: 2px 8px;
      display: inline;
      border-radius: 4px;
    }
  }

  .all {
  }
  .frontend {
    p {
      background: #3c96b4;
    }
  }
  .backend {
    p {
      background: #3aa537;
    }
  }
  .po-pm {
    p {
      background: #8c5ac8;
    }
  }
  .design {
    p {
      background: #ffd048;
    }
  }
  .marketing {
    p {
      background: #e5546c;
    }
  }
  .rrhh {
    p {
      background: #faa082;
    }
  }
  .qa {
    p {
      background: #000000;
      border: 1px solid white;
      color: white;
    }
  }
  .operations {
    p {
      background: #e677d7;
    }
  }
`;

export const InputCalendarStyle = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
  border-radius: 8px;

  .input-body-left-icon {
    margin-left: 8px;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  &.open {
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }
`;

export const SelectContainerStyle = styled.div`
  position: relative;

  .input-body-selected {
    position: absolute;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    transition: 0.3s ease-in-out all;
    pointer-events: none;
  }
  .selected-container {
    padding: 6px 12px 10px 16px;
  }

  .on-focus {
    .input-body-selected {
      visibility: hidden;
      opacity: 0;
    }
    .arrow-select {
      transform: rotate(180deg);
    }
  }
`;
