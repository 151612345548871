import styled from "styled-components";

export const RequestCardStyle = styled.div`
  border: 1px solid ${(props) => props.theme.bn.white};
  border-radius: 4px;
  width: fit-content;

  .request-card-content {
    border-radius: 2px;
    padding: 42px;
    width: 100%;
    max-width: 520px;
    background: ${(props) => props.theme.bn.black};
    &__name {
      font-size: 24px;
      color: ${(props) => props.theme.bn.white};
      margin-bottom: 8px;
    }
    &__team {
      margin-bottom: 24px;
      color: ${(props) => props.theme.bn.gray_18};
    }
    &__type {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }

    &__duration {
      display: flex;
      margin-bottom: 12px;
      font-weight: 500;
      color: ${(props) => props.theme.bn.white};
      &__text {
        margin-right: 12px;
        font-weight: 400;
        color: ${(props) => props.theme.bn.gray_18};
      }
      &__space {
        margin: 0px 8px;
        font-weight: 400;
        color: ${(props) => props.theme.bn.gray_18};
      }
    }
    &__po {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }

    &__comment {
      margin-top: 20px;
      &__title {
        font-weight: 400;
        color: ${(props) => props.theme.bn.gray_18};
        margin-bottom: 8px;
      }
      &__text {
        font-size: 12px;
      }
    }

    &__status {
      margin-top: 24px;
      display: flex;
      color: ${(props) => props.theme.bn.white};
      &__item {
        font-size: 12px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        span {
          margin-right: 8px;
        }
      }
    }

    .top-close {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      margin-bottom: 12px;
      img {
        width: 18px;
      }
    }
  }
`;
