const getEmailTemplate = (title: string, body: string) => {
    return `
    <html>
    <head>
        <style>
            body {
                background: #040404;
                overflow-x: hidden;
            }

            .wrapper {
                background: #040404;
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow-x: hidden;
            }

            .back-image {
                position: absolute;
                bottom: 0;
                right: 0;
                opacity: 0.2;
            }

            .back-image img {
                width: 80vw;
                transform: translateX(200px);
            }

            .card-info {
                background: #fff;
                padding: 32px;
                border-radius: 8px;
                max-width: 540px;
            }

            .card-info-title {
                font-family: "norms", sans-serif;
                font-size: 32px;
                color: #040404;
                margin-bottom: 24px;
            }

            .card-info-body {
                font-family: "norms", sans-serif;
                font-weight: 300;
                font-size: 24px;
                color: #040404;
            }
        </style>
    </head>

    <body>
        <div class="wrapper">
            <div class="back-image">
                <img src='https://vacations.aluxion.com/static/media/login-white.b4930b48.svg' />
            </div>
            <div class="card-info">
                <div class="card-info-title">
                    ${title}
                </div>
                <div class="card-info-body">
                    ${body}
                </div>
            </div>
        </div>
    </body>
    </html>
    `
}

export default getEmailTemplate;