import React from "react";

export const IndeterminateCheckbox = React.forwardRef<any, any>(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    // const checkboxContainerStyles: any = {
    //   active: checked,
    //   "mid-state": indeterminate
    // };

    return (
      <div className="checkbox-wrapper">
        <label className="checkbox-container">
          <input
            {...rest}
            type="checkbox"
            ref={resolvedRef}
            checked={checked}
          />
          <div className="checkmark-container">
            <div className="checkmark-item" />
          </div>
        </label>
      </div>
    );
  }
);
