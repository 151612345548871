/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Aluxion,
  AluxionWhite,
  moon,
  moonWhite,
} from "../../assets/images/images";
import { AIR_TABLE_KEY } from "../../env";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import Button from "../button/button";
import ModalCodeReview, {
  IReviews,
} from "../modal-code-review/modal-code-review";
import { HeaderStyle } from "./header-style";

const Header: React.FC = () => {
  const { toggleDarkMode, isDarkMode } = useDarkMode();

  const DEV_TEAMS = ["Frontend", "Backend"];

  const [showCodeReviewModal, setShowCodeReviewModal] = useState(false);
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [reviews, setReviews] = useState<IReviews>({
    name: "",
    last: {},
    actual: {},
    next: {},
  });

  useEffect(() => {
    getEmployees();
  }, [localStorage.getItem("loggedEmail")]);

  const getEmployees = async () => {
    try {
      const res: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 100,
            maxRecords: 1,
            filterByFormula: `FIND("${localStorage.getItem(
              "loggedEmail"
            )}", {Aluxioner Name})`,
          },
        }
      );
      let isDev = false;
      if (res.data.records.length > 0) {
        isDev = DEV_TEAMS.includes(res.data.records[0]?.fields?.Team);
        setIsDeveloper(isDev);
      }
      if (isDev) {
        await fetchReviews();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReviews = async () => {
    try {
      const start = moment().subtract("day", 14).format("DD/MM/YYYY");
      const end = moment().add("day", 14).format("DD/MM/YYYY");

      const res: any = await axios.get(
        "https://api.airtable.com/v0/appHXEAplyyE7zksw/Reviews",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            view: "Grid view",
            pageSize: 100,
            filterByFormula: `AND(
              FIND("${localStorage.getItem("loggedEmail")}", {revisor}),
              (AND(IS_AFTER({inicio}, DATETIME_PARSE('${start}',  'DD/MM/YYYY')),
              IS_BEFORE({fin}, DATETIME_PARSE('${end}', 'DD/MM/YYYY')))))`,
          },
        }
      );

      const indexProps: any = ["last", "actual", "next"];
      const mappedReviews = res.data.records.reduce(
        (acc: any, review: any, index: number) => ({
          ...acc,
          [indexProps[index]]: {
            start: moment(review.fields.inicio, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            ),
            end: moment(review.fields.fin, "YYYY-MM-DD").format("DD/MM/YYYY"),
            reviewed: review.fields.revisado,
          },
        }),
        {}
      );
      setReviews({
        name: res.data.records[0]?.fields.revisor,
        ...mappedReviews,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <HeaderStyle>
      <NavLink to="/">
        <div className="header-left">
          <div className="header-left__logo">
            <img src={!isDarkMode ? AluxionWhite : Aluxion} alt="aluxion" />
          </div>
          <div className="header-left__title">
            <p>vacations</p>
          </div>
        </div>
      </NavLink>
      <div className="header-right">
        <nav className="header-right-nav">
          <div className="header-right-nav__item">
            <NavLink exact to="/">
              Home
            </NavLink>
          </div>
          <div className="header-right-nav__item">
            <NavLink exact to="/status">
              Perfil
            </NavLink>
          </div>
          <div className="header-right-nav__item">
            <NavLink exact to="/tracker">
              Hour tracker
            </NavLink>
          </div>
          {isDeveloper && (
            <div className="header-right-nav__request">
              <Button
                type="primary"
                text="Code Reviews"
                onClick={() => setShowCodeReviewModal(true)}
              />
            </div>
          )}
          <div className="header-right-nav__request">
            <Button
              type="primary"
              text="Rellenar tracker"
              onClick={() =>
                window.open("https://airtable.com/shrCc3ZxFbuLcjrDM")
              }
            />
          </div>
          <div className="header-right-nav__request">
            <Button
              type="primary"
              text="Solicitar vacaciones"
              onClick={() =>
                window.open("https://airtable.com/shrZ8NbhEeZix9cOP")
              }
            />
          </div>
          <div
            className={"header-right-nav__theme"}
            onClick={() => toggleDarkMode()}
          >
            <img src={!isDarkMode ? moonWhite : moon} alt="aluxion" />
          </div>
          {showCodeReviewModal && (
            <ModalCodeReview
              reviews={reviews}
              onClose={() => setShowCodeReviewModal(false)}
              initialPositions={{
                x: 0,
                y: 0,
              }}
            />
          )}
        </nav>
      </div>
    </HeaderStyle>
  );
};

export default Header;
