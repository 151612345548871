import React, { useRef, useEffect } from "react";
import { CalendarDetailStyle } from "./calendar-detail-style";
import { Close, Check } from "../../assets/icons/icons";
import SVGIcon from "../svg-icon/svg-icon";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import moment from "moment";

interface ICalendarDetailProps {
  name: string;
  requestDate: string;
  startDate: string;
  finishDate: string;
  poName: string;
  team: string;
  type: string;
  onClose: () => void;
  managerApproved: boolean;
  rrhhApproved: boolean;
  initialPositions: {
    x: number;
    y: number;
  };
}

const CalendarDetail = (props: ICalendarDetailProps) => {
  const {
    name,
    requestDate,
    startDate,
    finishDate,
    poName,
    team,
    type,
    onClose,
    initialPositions,
    managerApproved,
    rrhhApproved,
  } = props;

  const { isDarkMode } = useDarkMode();
  const containerRef: any = useRef();

  let pos1 = initialPositions.x;
  let pos2 = initialPositions.y;
  let pos3 = 0;
  let pos4 = 0;

  useEffect(() => {
    dragElement(containerRef.current);
    document.body.style.overflowY = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    containerRef.current.style.top = `${initialPositions.y + 20}px`;
    containerRef.current.style.left = `${initialPositions.x - 30}px`;
  }, [initialPositions.x, initialPositions.y]);

  const dragElement = (elmnt: any) => {
    elmnt.onmousedown = dragMouseDown;
  };

  const dragMouseDown = (e: any) => {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const elementDrag = (e: any) => {
    if (containerRef && containerRef.current) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      containerRef.current.style.top = `${containerRef.current.offsetTop - pos2}px`;
      containerRef.current.style.left = `${containerRef.current.offsetLeft - pos1}px`;
    }
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const handleClose = () => {
    document.body.style.overflowY = "scroll";
    onClose();
  };

  return (
    <CalendarDetailStyle ref={containerRef} onClick={() => handleClose()}>
      <div className="calendar-detail-content" onClick={(ev) => ev.stopPropagation()}>
        <div className="top-close" onClick={() => handleClose()}>
          <SVGIcon size="32px" icon={Close} color={isDarkMode ? "#040404" : "#ffffff"} />
        </div>
        <div className="calendar-detail-content__name">
          <p>{name}</p>
        </div>
        <div className="calendar-detail-content__team">
          <p>{team}</p>
        </div>
        <div className="calendar-detail-content__type">
          <p>
            Tipo:<span>{type}</span>
          </p>
        </div>
        <div className="calendar-detail-content__type">
          <p>
            {" "}
            Pedidas el:<span>{moment(requestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
          </p>
        </div>
        <div className="calendar-detail-content__po">
          <p>
            PM/PO: <span>{poName}</span>
          </p>
        </div>
        <div className="calendar-detail-content__duration">
          <div className="calendar-detail-content__duration__text">
            <p>Duración:</p>
          </div>
          <div className="calendar-detail-content__duration__start">
            <p>{moment(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
          </div>
          <div className="calendar-detail-content__duration__space">
            <p>-</p>
          </div>
          <div className="calendar-detail-content__duration__end">
            <p>{moment(finishDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
          </div>
        </div>
        <div className="calendar-detail-content__status">
          <div className="calendar-detail-content__status__item">
            <span>
              {managerApproved && <SVGIcon icon={Check} color={isDarkMode ? "#040404" : "#ffffff"} size="16px" />}
            </span>
            <p>{managerApproved ? "Aprobado" : "Pendiente"} por Manager</p>
          </div>
          <div className="calendar-detail-content__status__item">
            <span>
              {rrhhApproved && <SVGIcon icon={Check} color={isDarkMode ? "#040404" : "#ffffff"} size="16px" />}
            </span>
            <p>{managerApproved ? "Aprobado" : "Pendiente"} por RRHH</p>
          </div>
        </div>
      </div>
    </CalendarDetailStyle>
  );
};

export default CalendarDetail;
