import styled from 'styled-components';

export const CalendarStyle = styled.div`
  min-width: 344px;
  max-width: 400px;
  background: #ffffff;
  z-index: 9;

  .calendar-container {
    display: block;
    position: relative;
    width: 346px;
    border-radius: 4px;
    border: 1px solid #ededed;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.04);
  }
`;

export const CalendarHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  padding-right: 8px;
  border-bottom: 1px solid #ededed;
  height: 48px;

  .calendar-header-date {
    display: flex;
    align-items: center;
  }

  .header-left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .current-month {
    cursor: pointer;
    width: 88px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Century Gothic', Arial, 'Helvetica Neue', sans-serif;
    color: #504c4d;
    margin-right: 4px;
    user-select: none;
  }

  .current-year {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Century Gothic', Arial, 'Helvetica Neue', sans-serif;
    color: #504c4d;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 3px 1px;

    &__icon {
      margin: 0 4px;

      svg path {
        fill: #000000;
        transition: all 0.2s ease-in-out;
      }
    }

    &__label {
      width: 48px;
    }

    &.open {
      border-color: #e31a18;

      &__icon {
        svg path {
          fill: #e31a18;
        }
      }
    }
  }

  .arrow-month {
    min-width: 24px;
    min-height: 24px;
    cursor: pointer;

    &.prev-month {
      margin-right: 8px;
    }
  }
`;

export const CalendarAlert = styled.div`
  padding: 0px 8px 8px;
`;

export const CalendarWeek = styled.div`
  display: grid;
  padding: 0 8px 8px;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px 8px;
  text-align: center;

  .calendar-week-day {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Century Gothic', Arial, 'Helvetica Neue', sans-serif;
    color: #d3d2d2;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    user-select: none;
  }
`;

export const CalendarCells = styled.div`
  .calendar-row {
    display: grid;
    padding: 0 8px 8px;
    grid-template-columns: repeat(7, 1fr);
    gap: 0px 8px;
  }

  .calendar-cell {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Century Gothic', Arial, 'Helvetica Neue', sans-serif;
    color: #504c4d;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    /* ADD STATES */

    &:hover:not( .selected ) {
      color: #e31a18;
      background: #fff2f2;
      border-color: #f9d1d1;
    }

    &.selected {
      color: #ffffff;
      background: #e31a18;
    }

    &.disabled {
      color: #d3d2d2;
      pointer-events: none;
    }

    &.no-allow {
      color: #d3d2d2;
      pointer-events: none;
    }

    /* This should be the class that we add to the
      days that already have a programmed gospel! */
    &.chosen {
      color: #d3d2d2;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        height: 6px;
        width: 6px;
        background: #c04140;
      }
    }
  }
`;

export const CalendarSelectMonth = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 2;
  display: none;
  border-radius: 5px;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 12px;
  padding: 12px;

  &.active {
    display: grid;
  }

  .calendar-select-month-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Century Gothic', Arial, 'Helvetica Neue', sans-serif;
    color: #504c4d;
  }
`;

export const CalendarSelectYear = styled.div`
  position: absolute;
  width: min-content;
  max-height: 114px;
  top: 44px;
  right: 8px;
  background: #ffffff;
  z-index: 2;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s;
  transform: translate3d(0, 0, 0);

  &.active {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 1s;
  }

  .calendar-select-year-item {
    cursor: pointer;
    padding: 8px;
  }

  .calendar-select-year-wrapper {
    width: 76px;
    max-height: 114px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #ededed;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    .calendar-select-year-item {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      font-family: 'Century Gothic', Arial, 'Helvetica Neue', sans-serif;
      color: #504c4d;
      padding: 10px 0;
      padding-left: 12px;

      &:not(:last-child) {
        border-bottom: 1px solid #ededed;
      }
    }
  }

  .calendar-select-year-close {
    width: 20px;
    height: 20px;
    border: 1px solid #dadada;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;
