import classnames from 'classnames';
import {
  addDays,
  addMonths,
  differenceInYears,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths,
  subYears,
  toDate
} from 'date-fns';
import es from 'date-fns/locale/es';
import React, { useEffect, useState } from 'react';
import {
  CalendarCells,
  CalendarHeader,
  CalendarSelectMonth,
  CalendarSelectYear,
  CalendarStyle,
  CalendarWeek
} from './calendar-style';

// Dependencias

export const isValidDate = (date: string) =>
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(date);

export const copyOf = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const capitalizeFirstLetter = (text: string) => {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

interface CalendarItemProps {
  onSelect: (
    value: string,
    closeCalendar: boolean,
    secondValue?: string
  ) => void;
  value?: any;
  forbidFutureDates?: boolean;
  forbidPastDates?: boolean;
  rangeSelect?: boolean;
  minAge?: number;
  maxAge?: number;
  className?: string;
  multiSelect?: boolean;
  onChangeMulti?: (values: string[]) => void;
}
const Calendar: React.FC<CalendarItemProps> = (props: CalendarItemProps) => {
  const [showYears, setShowYears] = useState<boolean>(false);
  const [showMonths, setShowMonths] = useState<boolean>(false);
  const [rangeSelectStep, setRangeSelectStep] = useState<number>(0);
  const [currentDate, setCurrentDate] = useState<any>(
    props.minAge ? subYears(new Date(), props.minAge) : new Date()
  );
  const [secondDate, setSecondDate] = useState<any>(
    props.rangeSelect ? new Date() : null
  );
  const [auxDate, setAuxDate] = useState<any>(new Date());
  const [multiValues, setMultiValues] = useState<any>([]);

  useEffect(() => {
    if (!props.multiSelect && props.value) {
      let rangeSelectStepAux = 1;
      const initialDateString = props.rangeSelect
        ? props.value[0]
        : props.value;
      if (isValidDate(initialDateString)) {
        const initialDate = parse(initialDateString, 'dd/MM/yyyy', new Date());
        if (initialDate.getTime() !== currentDate.getTime()) {
          setCurrentDate(initialDate);
        }
      }
      if (props.rangeSelect) {
        if (isValidDate(props.value[1])) {
          const secondDateNew = parse(props.value[1], 'dd/MM/yyyy', new Date());
          rangeSelectStepAux = 2;
          setSecondDate(secondDateNew);
          // if (secondDate.getTime() !== secondDateNew.getTime()) {
          // }
        }
        setRangeSelectStep(rangeSelectStepAux);
      }
    } else if (props.multiSelect && Array.isArray(props.value)) {
      const multiValuesAux = props.value.map((v: string) => v);
      setMultiValues(multiValuesAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (rangeSelectStep === 2) {
      props.onSelect(
        format(currentDate, 'dd/MM/yyyy'),
        true,
        format(secondDate, 'dd/MM/yyyy')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeSelectStep]);

  const isAllowSelect = (date: Date) => {
    if (props.minAge && differenceInYears(new Date(), date) < props.minAge) {
      return false;
    }
    if (props.forbidFutureDates && isFuture(date)) {
      return false;
    }
    if (props.forbidPastDates && isPast(date) && !isToday(date)) {
      return false;
    }
    return true;
  };

  const nextMonth = () => {
    // tslint:disable-next-line:no-shadowed-variable
    const nextMonth = addMonths(auxDate, 1);

    if (isAllowSelect(nextMonth)) {
      setAuxDate(nextMonth);
    }
  };

  const prevMonth = () => {
    // tslint:disable-next-line:no-shadowed-variable
    const prevMonth = subMonths(auxDate, 1);

    if (isAllowSelect(prevMonth)) {
      setAuxDate(prevMonth);
    }
  };

  const renderHeader = () => {
    const dateFormatMonth = 'MMMM';
    const dateFormatYear = 'yyyy';

    return (
      <CalendarHeader>
        <div className="arrow-month prev-month" onClick={() => prevMonth()}>
          <img src={''} alt="" />
        </div>
        {/* Current Month */}
        <div className="calendar-header-date">
          <div className="current-month">
            <p onClick={() => setShowMonths(true)}>
              {capitalizeFirstLetter(
                format(auxDate, dateFormatMonth, {
                  locale: es,
                })
              )}
            </p>
          </div>
          {/* Current Year */}
          <div className="current-year">
            <p onClick={() => setShowYears(true)}>
              {capitalizeFirstLetter(
                format(auxDate, dateFormatYear, {
                  locale: es,
                })
              )}
            </p>
          </div>
        </div>
        <div className="arrow-month next-month" onClick={() => nextMonth()}>
          <img src={''} alt="" />
        </div>
      </CalendarHeader>
    );
  };

  const renderDaysHeader = () => {
    const dateFormat = 'E';
    const days = [];
    // "L","M","X","J","V","S","D"
    const startDate = startOfWeek(auxDate, {
      weekStartsOn: 1,
    });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-week-day" key={i}>
          <div>
            <p>
              {format(addDays(startDate, i), dateFormat, {
                locale: es,
              })}
            </p>
          </div>
        </div>
      );
    }
    return <CalendarWeek>{days}</CalendarWeek>;
  };

  const renderCells = () => {
    const { rangeSelect, multiSelect } = props;

    const monthStart = startOfMonth(auxDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = 'dd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';
    let multiSelected = false;
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        if (multiSelect) {
          multiSelected = false;
          // eslint-disable-next-line no-loop-func
          multiValues.forEach((multiValue: string) => {
            if (!multiSelected) {
              multiSelected = isSameDay(
                day,
                parse(multiValue, 'dd/MM/yyyy', new Date())
              );
            }
          });
        }
        days.push(
          <div
            className={`calendar-cell
              ${
                !isSameMonth(day, monthStart)
                  ? ' disabled'
                  : !isAllowSelect(day)
                  ? ' no-allow'
                  : multiSelected
                  ? 'selected-multi'
                  : currentDate &&
                    !multiSelect &&
                    ((rangeSelect && rangeSelectStep !== 0) || !rangeSelect) &&
                    (isSameDay(day, currentDate) ||
                      (secondDate && isSameDay(day, secondDate)))
                  ? ' selected'
                  : (rangeSelect &&
                      currentDate &&
                      secondDate &&
                      isAfter(currentDate, day) &&
                      isBefore(secondDate, day)) ||
                    (isBefore(currentDate, day) &&
                      isAfter(secondDate, day) &&
                      !multiSelect)
                  ? ' in-selected'
                  : ''
              }
            `.trim()}
            key={format(day, 'dd-MM-yyyy')}
            data-testid={format(day, 'dd-MM-yyyy')}
            onClick={() => onDateClick(toDate(cloneDay))}
          >
            {/* Days */}
            <div>{formattedDate}</div>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="calendar-row" key={format(day, 'dd-MM-yyyy')}>
          {days}
        </div>
      );
      days = [];
    }
    if (rows.length < 6) {
      const newsRows: number = 6 - rows.length;
      for (let i = 0; i < newsRows; i++) {
        rows.push(
          <div className="calendar-row" key={`calendar-row-empty-${i}`}>
            <p />
          </div>
        );
      }
    }
    return <CalendarCells>{rows}</CalendarCells>;
  };

  const renderSelectYear = () => {
    const { minAge, maxAge } = props;

    const currentYear = new Date().getFullYear();
    const maxYear = minAge ? currentYear - minAge : 2050;
    const minYear = currentYear - (maxAge ? maxAge : 150);
    const years = [];
    for (let i = maxYear; i >= minYear; i--) {
      const child = [<p key={i}>{i}</p>];
      years.push(
        <div
          className={classnames('calendar-select-year-item', {
            'no-allow': !isAllowSelect(auxDate),
          })}
          key={i}
          onClick={() => {
            const selected = setYear(auxDate, i);
            onDateClick(selected, true);
          }}
        >
          {child}
        </div>
      );
    }

    return (
      <CalendarSelectYear className={classnames({ active: showYears })}>
        <div className="calendar-select-year-wrapper">
          <div
            className="calendar-select-year-close"
            onClick={() => setShowYears(false)}
          >
            {/* <img src={""} alt="cerrar seleccion de año" /> */}
          </div>
          {years}
        </div>
      </CalendarSelectYear>
    );
  };

  const renderSelectMonth = () => {
    let tempDate = setMonth(new Date(), 0);
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = setMonth(auxDate, i);
      months.push(
        <div
          className={classnames('calendar-select-month-item', {
            'no-allow': !isAllowSelect(monthDate),
          })}
          key={i}
          onClick={() => {
            const selected = setMonth(auxDate, i);
            onDateClick(selected, true);
          }}
        >
          {format(tempDate, 'MMMM', { locale: es })}
        </div>
      );
      tempDate = addMonths(tempDate, 1);
    }

    return (
      <CalendarSelectMonth className={classnames({ active: showMonths })}>
        {months}
      </CalendarSelectMonth>
    );
  };

  // tslint:disable-next-line:no-shadowed-variable
  const onDateClick = (date: Date, auxDate?: boolean) => {
    const { onSelect, rangeSelect, multiSelect, onChangeMulti } = props;

    if (auxDate) {
      setAuxDate(date);
      setShowMonths(false);
      setShowYears(false);
    } else if (rangeSelect) {
      if (rangeSelectStep === 0) {
        setRangeSelectStep(1);
        setCurrentDate(date);
        setSecondDate(date);
        setShowMonths(false);
        setShowYears(false);
      } else if (rangeSelectStep === 1) {
        setRangeSelectStep(2);
        setSecondDate(date);
        setShowMonths(false);
        setShowYears(false);
      } else {
        setRangeSelectStep(0);
        setCurrentDate(new Date());
        setSecondDate(new Date());
        setShowMonths(false);
        setShowYears(false);
      }
    } else if (multiSelect && onChangeMulti) {
      let newMultiValues = copyOf(multiValues);
      const isoDate = format(date, 'dd/MM/yyyy');
      if (newMultiValues.includes(isoDate)) {
        newMultiValues = newMultiValues.filter(
          (val: string) => val !== isoDate
        );
      } else newMultiValues.push(isoDate);
      setMultiValues(newMultiValues);
      setShowMonths(false);
      setShowYears(false);
      if (onChangeMulti) {
        onChangeMulti(newMultiValues);
      }
    } else if (isAllowSelect(date)) {
      setCurrentDate(date);
      setShowMonths(false);
      setShowYears(false);
      if (onSelect) {
        onSelect(format(date, 'dd/MM/yyyy'), true);
      }
    }
  };

  // const nextYear = () => {
  //   const { onSelect } = props;
  //   const nextYear = addYears(auxDate, 1);

  //   if (isAllowSelect(nextYear)) {
  //     setAuxDate(nextYear);
  //     if (onSelect) {
  //       onSelect(format(nextYear, "dd/MM/yyyy"), false);
  //     }
  //   }
  // };

  // const prevYear = () => {
  //   const { onSelect } = props;
  //   const prevYear = subYears(auxDate, 1);

  //   if (isAllowSelect(prevYear)) {
  //     setAuxDate(prevYear);
  //     if (onSelect) {
  //       onSelect(format(prevYear, "dd/MM/yyyy"), false);
  //     }
  //   }
  // };

  return (
    <CalendarStyle
      className={classnames(props.className || '', {
        range: props.rangeSelect,
      })}
    >
      <div className="calendar-container">
        {renderHeader()}
        {renderDaysHeader()}
        {renderCells()}
        {renderSelectYear()}
        {renderSelectMonth()}
      </div>
    </CalendarStyle>
  );
};

export default Calendar;
