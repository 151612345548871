import styled from "styled-components";
import { Check, DobleCheck } from "../../assets/icons/icons";

export const TrackerStyle = styled.div`
  padding: 120px 60px 80px;

  .dot-circle-container {
    margin-right: 8px;
    margin-bottom: 24px;
    display: flex;
    .dot-circle {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: white;
      border: 1px solid black;
      margin-left: 4px;
    }
  }

  .tracker-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    z-index: 2;
  }
  .tracker-nav-left {
    display: flex;
    align-items: flex-end;
  }

  .tracker-nav-right {
    display: flex;
    align-items: flex-end;

    .tracker-nav-right-modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;
      transition: 0.3s ease-in-out all;
      opacity: 0;
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      .tracker-nav-right-modal-content {
        padding: 60px 20px 20px;
        background: ${(props) => props.theme.bn.black};
        position: relative;
        &__close  {
          position: absolute;
          top: 12px;
          right: 12px;
          cursor: pointer;
        }
        &__title {
          font-size: 24px;
          font-family: "norms";
          font-weight: 400;
          margin-bottom: 24px;
          color: ${(props) => props.theme.bn.white};
        }
        &__item {
          padding: 2px 5px;
          border-radius: 2px;
          margin-bottom: 6px;
          color: white;
        }
      }
    }
  }

  .tracker-nav-tab {
    padding: 8px;
    cursor: pointer;
    &.active {
      border-bottom: 1px solid ${(props) => props.theme.bn.white};
    }
  }

  .tracker-filter-container {
    display: flex;
    align-items: center;
  }

  .tracker-filter-alert {
    height: 100%;
    background-color: ${(props) => props.theme.bn.white};
    color: ${(props) => props.theme.bn.black};
    padding: 16px;
  }

  .tracker-filter-item {
    margin-right: 12px;

    min-width: 140px;
    &:last-child {
      margin-right: 0px;
    }
    &__button {
      margin-top: 22px;
    }
  }
  .table-filters {
    &__input {
      /* max-width: 216px; */
    }
  }

  .table-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    &__item {
      margin-left: 12px;
    }
  }

  .my-requests-container {
    margin-top: 80px;
    margin-left: 80px;
    &__grid {
      width: fit-content;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }
  }

  .task-tooltip {
    max-width: 300px;
    font-size: 16px;
    padding: 16px;
    line-height: 16px;
  }

  .table-filters {
    display: flex;
    align-items: center;
    &__checkbox {
      margin-left: 24px;
      padding-left: 24px;
      border-left: 1px solid ${(props) => props.theme.bn.gray_50};
    }
  }

  /* Calendar xtyler */

  .rbc-header + .rbc-header {
    border-left: 1px solid ${(props) => props.theme.bn.gray_50};
  }
  .rbc-day-bg {
    &.rbc-today {
      background: ${(props) => props.theme.bn.black};
    }
  }
  .rbc-header {
    font-size: 16px;
    font-family: "norms";
    font-weight: 400;
    padding: 8px;
    color: ${(props) => props.theme.bn.gray_18};
    border-bottom: 1px solid ${(props) => props.theme.bn.gray_50};
  }
  .rbc-event {
    background: ${(props) => props.theme.data.purple};
    color: white;

    /* turquoise */
    /* pale_blue */
    /* purple */
    /* pink */
    /* red */
    /* orange */
  }

  .rbc-btn-group {
    button {
      color: ${(props) => props.theme.bn.white};
    }
  }

  .rbc-toolbar-label {
    text-align: left;
    font-size: 24px;
    font-family: "norms";
    font-weight: 400;
    text-transform: capitalize;
    padding: 0px;
  }

  .rbc-date-cell {
    font-size: 24px;
    font-family: "norms";
    font-weight: 400;
    padding: 8px;
    color: ${(props) => props.theme.bn.gray_50};
    &.rbc-now.rbc-current {
      font-weight: 500;
      color: ${(props) => props.theme.bn.white};
    }
  }

  .rbc-show-more {
    font-size: 16px;
    font-family: "norms";
    font-weight: 400;
    padding: 4px;
    color: ${(props) => props.theme.bn.gray_18};
  }

  .rbc-off-range-bg {
    background: ${(props) => props.theme.bn.gray_80};
  }

  .rbc-overlay {
    color: ${(props) => props.theme.bn.black};
    background-color: ${(props) => props.theme.bn.black} !important;
  }

  .rbc-toolbar {
    flex-direction: row-reverse;
  }

  .custom-event {
    display: flex;
    &::before  {
      content: "";
      background-size: contain;
      z-index: 999;
      margin-right: 8px;
    }
    &.manager-check {
      &::before  {
        width: 16px;
        height: 16px;

        background: url(${Check});
      }
    }
    &.rrhh-check {
      &::before  {
        content: "";
        width: 16px;
        height: 16px;
        background: url(${Check});
      }
    }
    &.manager-check.rrhh-check {
      &::before  {
        content: "";
        width: 16px;
        height: 16px;
        background: url(${DobleCheck});
      }
    }
  }
`;
