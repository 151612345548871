/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useRef, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Close } from "../../assets/icons/icons";
import Button from "../../components/button/button";
import SVGIcon from "../../components/svg-icon/svg-icon";
import { AIR_TABLE_KEY } from "../../env";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import HomeAdminTable from "./components/home-admin-table";
import HomeCalendar from "./components/home-calendar";
import HomeMyRequests from "./components/home-my-requests";
import { HomeStyle } from "./home-style";

interface IHomeProps {
  userEmail: string;
}

const Home = (props: IHomeProps) => {
  const { userEmail } = props;
  const [section, setSection] = useState<"table" | "calendar" | "my-requests">(
    "calendar"
  );
  const [data, setData] = useState<any>([]);
  const [currentDate] = useState(moment().toISOString());
  const [isAdmin, setIsAdmin] = useState(false);
  const [workLeaveToday, setWorkLeaveToday] = useState<any[]>([]);
  const firstTimeCrack = useRef<boolean>(true);
  const [modalTodayLeaves, setModalTodayLeaves] = useState<boolean>(false);
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    checkIfAdmin();
  }, [userEmail]);

  const checkIfAdmin = async () => {
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Admins",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );
    const isAdminResp =
      res.data.records.filter((rec: any) => rec.fields.Email === userEmail)
        .length > 0;
    setIsAdmin(isAdminResp);
  };

  useEffect(() => {
    getWorkLeaves();
  }, []);

  const getWorkLeaves = async () => {
    try {
      const initDate = moment(currentDate)
        .startOf("month")
        .add(-1, "days")
        .format("DD/MM/YYYY");
      const finalDate = moment(currentDate)
        .endOf("month")
        .add(1, "days")
        .format("DD/MM/YYYY");
      const initMonth = moment(currentDate)
        .startOf("month")
        .format("DD/MM/YYYY");
      const res: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 100,
            offset: "",
            filterByFormula: `OR(AND(
              IS_AFTER({Día inicio}, DATETIME_PARSE('${initDate}',  'DD/MM/YYYY')), 
              IS_BEFORE({Día inicio}, DATETIME_PARSE('${finalDate}', 'DD/MM/YYYY'))
              ), 
              AND(
              IS_AFTER({Día fin}, DATETIME_PARSE('${initDate}',  'DD/MM/YYYY')), 
              IS_BEFORE({Día inicio}, DATETIME_PARSE('${initMonth}', 'DD/MM/YYYY'))
              ))`,
          },
        }
      );
      let workLeaves = [];
      workLeaves = res.data.records.map((item: any, index: number) => ({
        title: item.fields["Aluxioner Name"],
        start: moment(item.fields["Día inicio"], "YYYY-MM-DD").add("hour", 12),
        end: moment(item.fields["Día fin"], "YYYY-MM-DD").add("hour", 12),
        raw: item,
        resources: [index],
      }));
      setData(workLeaves);

      const calendarBackgroundElements = Array.from(
        document.getElementsByClassName("rbc-day-bg")
      );
      const calendarActiveBackgroundElements: any[] = [];
      calendarBackgroundElements.forEach((elem) => {
        if (elem.className.indexOf("rbc-off-range-bg") < 0) {
          calendarActiveBackgroundElements.push(elem);
        }
      });
      let currentDay = moment(currentDate).startOf("month");
      const daysInMonth = currentDay.daysInMonth();
      for (let i = 0; i < daysInMonth; i += 1) {
        const todayLeaves: any[] = [];
        // eslint-disable-next-line no-loop-func
        workLeaves.forEach((workLeave: any) => {
          if (
            moment(workLeave.start).isSameOrBefore(currentDay, "day") &&
            moment(workLeave.end).isSameOrAfter(currentDay, "day")
          ) {
            todayLeaves.push(workLeave);
          }
        });

        const todayTeams = todayLeaves.map(
          (todayLeave) => todayLeave.raw.fields.Team
        );
        const hasDuplicatedTeams =
          new Set(todayTeams).size !== todayTeams.length;
        if (hasDuplicatedTeams) {
          const currentCalendarElem = calendarActiveBackgroundElements[
            i
          ] as HTMLElement;
          currentCalendarElem.style.background = "rgba(255, 0, 0, 0.13)";
        }
        currentDay = currentDay.add(1, "day");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // let searchWaiter: any = null;
  // const handleEmployees = async (value: string) => {
  //   if (searchWaiter) {
  //     clearInterval(searchWaiter);
  //   }
  //   try {
  //     searchWaiter = setTimeout(async () => {
  //       const res: any = await axios.get("https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Global%20status", {
  //         params: {
  //           api_key: AIR_TABLE_KEY,
  //           filterByFormula: value ? `SEARCH("${value}", Name) > 0` : "",
  //         },
  //       });
  //       const employeesData = res.data.records.map((item: any) => ({
  //         value: item.fields.Name,
  //         label: item.fields.Name,
  //       }));
  //       setEmployees(employeesData);
  //     }, 750);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const getEventClassName = (event: any) => {
    switch (event.raw.fields.Team) {
      case "Frontend":
        return "front-team";
      case "Backend":
        return "back-team";
      case "PO/PMs":
        return "management-team";
      case "Design":
        return "design-team";
      case "Marketing":
        return "marketing-team";
      case "RRHH":
        return "rrhh-team";
      case "QA":
        return "qa-team";
      case "Operations":
        return "operations-team";
      default:
        return "operations-team";
    }
  };

  const handleChangeSection = (sec: "table" | "calendar" | "my-requests") => {
    setSection(sec);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentTodayLeaves = () => {
    return data.filter(
      (workLeave: any) =>
        moment(workLeave.start).isSameOrBefore(currentDate, "day") &&
        moment(workLeave.end).isSameOrAfter(currentDate, "day")
    );
  };

  useEffect(() => {
    if (firstTimeCrack.current && data.length > 0) {
      firstTimeCrack.current = false;
      setWorkLeaveToday(currentTodayLeaves());
    }
  }, [currentTodayLeaves, data]);

  return (
    <>
      <HomeStyle>
        <div className="home-nav">
          <div className="home-nav-left">
            <div
              className={`home-nav-tab ${
                section === "calendar" ? "active" : ""
              }`}
              onClick={() => handleChangeSection("calendar")}
            >
              Calendar
            </div>
            <div
              className={`home-nav-tab ${
                section === "my-requests" ? "active" : ""
              }`}
              onClick={() => handleChangeSection("my-requests")}
            >
              Mis peticiones
            </div>
            {isAdmin && (
              <div
                className={`home-nav-tab ${
                  section === "table" ? "active" : ""
                }`}
                onClick={() => handleChangeSection("table")}
              >
                Tabla Admin
              </div>
            )}
          </div>
          <div className="home-nav-right">
            <div className="home-nav-right-button">
              <Button
                disabled={workLeaveToday.length < 1}
                type="primary"
                size="small"
                text="Ausencias de hoy"
                onClick={() => setModalTodayLeaves(true)}
              />
            </div>

            <div
              className={`home-nav-right-modal ${
                modalTodayLeaves ? "active" : ""
              }`}
            >
              <div className="home-nav-right-modal-content">
                <div
                  className="home-nav-right-modal-content__close"
                  onClick={() => setModalTodayLeaves(false)}
                >
                  <SVGIcon
                    size="32px"
                    icon={Close}
                    color={isDarkMode ? "#040404" : "#ffffff"}
                  />
                </div>
                <div className="home-nav-right-modal-content__title">
                  <p>Aluxioner ausentes hoy:</p>
                </div>
                <div className="home-nav-right-modal-content__content">
                  {workLeaveToday.map((item, index) => (
                    <div
                      key={`work-leave-today-${index}`}
                      className={`home-nav-right-modal-content__item ${getEventClassName(
                        item
                      )}`}
                    >
                      <p>{item.title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-container">
          {section === "my-requests" ? (
            <HomeMyRequests userEmail={userEmail} />
          ) : section === "table" && isAdmin ? (
            <HomeAdminTable />
          ) : (
            <HomeCalendar />
          )}
        </div>
      </HomeStyle>
    </>
  );
};

export default Home;
