import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Close } from "../../assets/icons/icons";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import SVGIcon from "../svg-icon/svg-icon";
import { TrackerDetailStyle } from "./tracker-detail-style";

interface ITrackerDetailProps {
  name: string;
  date: string;
  project: string;
  task: string;
  blockers: string;
  hours: number;
  onClose: () => void;
  initialPositions: {
    x: number;
    y: number;
  };
}

const TrackerDetail = (props: ITrackerDetailProps) => {
  const {
    name,
    date,
    project,
    task,
    blockers,
    hours,
    onClose,
    initialPositions,
  } = props;

  const { isDarkMode } = useDarkMode();
  const containerRef: any = useRef();

  let pos1 = initialPositions.x;
  let pos2 = initialPositions.y;
  let pos3 = 0;
  let pos4 = 0;

  useEffect(() => {
    dragElement(containerRef.current);
    document.body.style.overflowY = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    containerRef.current.style.top = `${initialPositions.y + 20}px`;
    containerRef.current.style.left = `${initialPositions.x - 30}px`;
  }, [initialPositions.x, initialPositions.y]);

  const dragElement = (elmnt: any) => {
    elmnt.onmousedown = dragMouseDown;
  };

  const dragMouseDown = (e: any) => {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const elementDrag = (e: any) => {
    if (containerRef && containerRef.current) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      containerRef.current.style.top = `${
        containerRef.current.offsetTop - pos2
      }px`;
      containerRef.current.style.left = `${
        containerRef.current.offsetLeft - pos1
      }px`;
    }
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const handleClose = () => {
    document.body.style.overflowY = "scroll";
    onClose();
  };

  return (
    <TrackerDetailStyle ref={containerRef} onClick={() => handleClose()}>
      <div
        className="tracker-detail-content"
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className="top-close" onClick={() => handleClose()}>
          <SVGIcon
            size="32px"
            icon={Close}
            color={isDarkMode ? "#040404" : "#ffffff"}
          />
        </div>
        <div className="tracker-detail-content__name">
          <p>{name}</p>
        </div>
        <div className="tracker-detail-content__project">
          <p>
            Proyecto:<span>{project}</span>
          </p>
        </div>
        <div className="tracker-detail-content__type">
          <p>
            Horas:<span>{hours}</span>
          </p>
        </div>
        <div className="tracker-detail-content__type">
          <p>
            {" "}
            Fecha:<span>{moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
          </p>
        </div>
        <div className="tracker-detail-content__blockers">
          <p>
            Stoppers: <span>{blockers}</span>
          </p>
        </div>
        <div className="tracker-detail-content__task">
          <p>
            Tareas: <span>{task}</span>
          </p>
        </div>
      </div>
    </TrackerDetailStyle>
  );
};

export default TrackerDetail;
