import styled from "styled-components";

export const TrackerDetailStyle = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  padding: 24px;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(180%) blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;

  .tracker-detail-content {
    border-radius: 2px;
    padding: 42px;
    width: 100%;
    max-width: 520px;
    background: ${(props) => props.theme.bn.black};
    &__name {
      font-size: 24px;
      color: ${(props) => props.theme.bn.white};
      margin-bottom: 16px;
    }
    &__type {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }
    &__project {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }
    &__task {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }
    &__blockers {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }

    .top-close {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      margin-bottom: 12px;
      img {
        width: 18px;
      }
    }
  }
`;
