import styled from "styled-components";

export const RequestAdminModalStyle = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  padding: 24px;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(180%) blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;

  .request-admin-modal-content {
    border-radius: 2px;
    padding: 42px;
    width: 100%;
    max-width: 520px;
    background: ${(props) => props.theme.bn.black};
    &__name {
      font-size: 24px;
      color: ${(props) => props.theme.bn.white};
      margin-bottom: 8px;
    }
    &__team {
      margin-bottom: 24px;
      color: ${(props) => props.theme.bn.gray_18};
    }
    &__type {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }

    &__duration {
      display: flex;
      margin-bottom: 12px;
      font-weight: 500;
      color: ${(props) => props.theme.bn.white};
      &__text {
        margin-right: 12px;
        font-weight: 400;
        color: ${(props) => props.theme.bn.gray_18};
      }
      &__space {
        margin: 0px 8px;
        font-weight: 400;
        color: ${(props) => props.theme.bn.gray_18};
      }
    }
    &__po {
      margin-bottom: 12px;
      color: ${(props) => props.theme.bn.gray_18};
      span {
        margin-left: 8px;
        color: ${(props) => props.theme.bn.white};
      }
    }

    &__status {
      margin-top: 24px;
      display: flex;
      color: ${(props) => props.theme.bn.white};
      &__item {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-right: 24px;
        label {
          margin-right: 8px;
        }
      }
    }

    &__buttons {
      display: flex;
      margin-right: -12px;
      margin-top: 24px;
    }
    &__button {
      width: 100%;
      margin-right: 12px;
    }

    .top-close {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      margin-bottom: 12px;
      img {
        width: 18px;
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #50eb9d;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #50eb9d;
  }

  input:checked + .slider:before {
    transform: translateX(15px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
