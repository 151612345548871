import styled from "styled-components";

export const StatusStyle = styled.div`
  .status-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .status-nav-left {
    display: flex;
  }

  .status-nav-right {
    display: flex;
    .status-nav-button {
      margin-left: 12px;
      min-width: 80px;
    }
  }

  .status-nav-tab {
    padding: 8px;
    cursor: pointer;
    &.active {
      border-bottom: 1px solid black;
    }
  }

  .status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 120px 60px 80px;
  }

  .status-card {
    padding: 42px;
    border-radius: 4px;
    background: #fff;
    color: black;
    border: 1px solid black;
    .status-card-title {
      margin-bottom: 24px;
    }
    .status-card-body {
      display: grid;
      grid-template-columns: 1fr 1fr 20px;
      grid-gap: 12px;
      &__title {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.4);
      }
      &__field {
        font-size: 20px;
        font-weight: 600;
        text-align: right;
        display: flex;
        justify-content: flex-end;
      }
      &__tooltip {
        cursor: pointer;
      }
    }
  }

  .table-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    &__item {
      margin-left: 12px;
    }
  }

  .tooltip {
    border: 1px solid red;
    &:hover {
      cursor: pointer;
    }
  }
`;
