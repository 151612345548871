/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import Button from "../../../components/button/button";
import { Checkbox } from "../../../components/checkbox/checkbox";
import InputCalendar from "../../../components/input/input-calendar";
import { MainTable } from "../../../components/main-table/main-table-layout";
import { AIR_TABLE_KEY } from "../../../env";
import { IOptions } from "../../../models/options";
import { reduceArrayBy } from "../../../utils/generic-functions";
import { isEmpty } from "../../../utils/validations";

interface ITrackerAdminTable {
  userEmail: string;
}

const TrackerAdminTable: React.FC<ITrackerAdminTable> = ({ userEmail }) => {
  const MAX_CHARACTERS = 200;
  const DEFAULT_FILTERS = {
    "Fecha Hour Tracker": new Date(),
  };

  const [isFilterChecked, setIsFilterChecked] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [offsets, setOffsets] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<any>(DEFAULT_FILTERS);

  const trackers = useRef([]);

  useEffect(() => {
    getTrackers({ filters });
  }, [filters]);

  useEffect(() => {
    isFilterChecked ? filterAluxioners() : getTrackers({ filters });
  }, [isFilterChecked]);

  useEffect(() => {
    getEmployees();
    getTrackers({ filters });
    return () => {
      setOffsets([]);
      setData([]);
      setPage(0);
      setFilters(DEFAULT_FILTERS);
    };
  }, []);

  const filterAluxioners = () => {
    const employeesMails = reduceArrayBy(employees, "email");
    const trackerEmployeesNames = reduceArrayBy(
      trackers.current,
      "Aluxioner"
    ).map((track: string) => track?.split(/[()]/)[1] || "");

    const filtered = employeesMails.filter(
      (employee: string) =>
        employee && !trackerEmployeesNames.includes(employee)
    );

    const filteredEmployees = employees.filter((employee: any) =>
      filtered.includes(employee.email)
    );

    setData(filteredEmployees);
  };

  const getEmployees = async () => {
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Aluxioners",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );

    const res2: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Talent",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );

    const employeesData = res.data.records
      .map((item: any) => ({
        name: item.fields.Name,
        email: item.fields.Email,
      }))
      .concat(
        res2.data.records.map((item: any) => ({
          name: item.fields.Name,
          email: item.fields.Email,
        }))
      );
    setEmployees(employeesData);
  };

  const getFilter = (filtersObj?: any) => {
    const filtersAux = [];
    if (filtersObj) {
      if (
        filtersObj["Fecha Hour Tracker"] &&
        !isEmpty(filtersObj["Fecha Hour Tracker"])
      ) {
        filtersAux.push(
          `FIND("${moment(filtersObj["Fecha Hour Tracker"]).format(
            "YYYY-MM-DD"
          )}", {Fecha Hour Tracker})`
        );
      }
    }
    return filtersAux.length > 0 ? `AND(${filtersAux.join(",")})` : "";
  };

  const getTrackers = async (options?: IOptions) => {
    const newPage =
      options?.next === undefined ? 0 : options?.next ? page + 1 : page - 1;
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appHXEAplyyE7zksw/Table%201",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 10,
          offset: offsets[newPage - 1] || "",
          filterByFormula: getFilter(options?.filters),
        },
      }
    );

    let trackersMapped = [];

    trackersMapped = res.data.records.map((item: any, index: number) => ({
      ...item.fields,
      "Fecha Hour Tracker": moment(
        item.fields["Fecha Hour Tracker"],
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY"),
    }));

    trackers.current = trackersMapped;

    if (!isFilterChecked) {
      setData(trackersMapped);
    } else {
      filterAluxioners();
    }

    if (
      res.data.offset &&
      !offsets.includes(res.data.offset) &&
      options?.next !== false
    ) {
      setOffsets(offsets.concat([res.data.offset]));
    }
    setPage(newPage);
  };

  const handleFilters = (name: string, value: any) => {
    filters[name] = value;
    setFilters({ ...filters });
  };

  const columns = React.useMemo(
    () =>
      isFilterChecked
        ? [
            {
              Header: "Aluxioner Name",
              accessor: "name",
              sizeCol: 3,
            },
            {
              Header: "Aluxioner Email",
              accessor: "email",
              sizeCol: 3,
            },
          ]
        : [
            {
              Header: "Aluxioner Name",
              accessor: "Aluxioner",
              sizeCol: 2,
            },
            {
              Header: "Departamento",
              accessor: "Departamento",
              sizeCol: 2,
            },
            {
              Header: "Fecha Hour Tracker",
              accessor: "Fecha Hour Tracker",
              sizeCol: 2,
            },
            {
              Header: "Horas",
              accessor: "Horas",
              sizeCol: 1,
            },
            {
              Header: "Proyecto",
              accessor: "Proyecto",
              sizeCol: 2,
            },
            {
              Header: "Tareas realizadas",
              accessor: (tracker: any) => (
                <>
                  {tracker["Tareas realizadas"]?.length > MAX_CHARACTERS ? (
                    <>
                      <ReactTooltip className="task-tooltip" />
                      <div data-tip={tracker["Tareas realizadas"]}>
                        {tracker["Tareas realizadas"].substring(
                          0,
                          MAX_CHARACTERS
                        )}
                        ...
                      </div>
                    </>
                  ) : (
                    tracker["Tareas realizadas"]
                  )}
                </>
              ),
              sizeCol: 4,
            },
            {
              Header: "Blockers",
              accessor: "Blockers",
              sizeCol: 3,
            },
          ],
    [isFilterChecked, filters]
  );

  return (
    <>
      <div className={"table-filters"}>
        <InputCalendar
          onBlur={(date: any) => {
            handleFilters("Fecha Hour Tracker", date);
          }}
          value={filters["Fecha Hour Tracker"]}
          className={"table-filters__input"}
          icon={true}
          placeholder={"00/00/0000"}
        />
        <Checkbox
          className={"table-filters__checkbox"}
          checked={isFilterChecked}
          onChange={(e: any) => {
            setIsFilterChecked(e.target.checked);
          }}
        >
          Filtrar aluxioners que no rellenaron el tracker el día seleccionado
        </Checkbox>
      </div>
      <MainTable data={data} columns={columns} />
      <div className="table-buttons">
        <div className="table-buttons__item">
          <Button
            size="small"
            type="ghost"
            text="Anterior"
            disabled={page <= 0}
            onClick={() => getTrackers({ next: false })}
          />
        </div>
        <div className="table-buttons__item">
          <Button
            size="small"
            type="ghost"
            text="Siguiente"
            disabled={page >= offsets.length}
            onClick={() => getTrackers({ next: true })}
          />
        </div>
      </div>
    </>
  );
};

export default TrackerAdminTable;
