import styled from "styled-components";

export const MainTableContainer = styled.div`
  z-index: 0;
  position: relative;
  .table {
    border: 1px solid ${(props) => props.theme.bn.gray_70};
    overflow: hidden;
    border-radius: 3px;
    .tr {
      cursor: pointer;
      min-width: 100%;
      min-height: 56px;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      /* overflow: hidden;  */
      min-width: 56px;
      &.visible {
        overflow: visible;
      }

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        /* z-index: 1; */
      }
    }

    .td {
      padding: 8px 16px;
      background: ${(props) => props.theme.bn.black};
      border-bottom: 1px solid ${(props) => props.theme.bn.gray_70};
    }

    .th {
      padding: 17px 16px;
      background: ${(props) => props.theme.bn.black};
      z-index: 9;
    }

    &.sticky {
      &.no-scroll {
        overflow: initial;
      }
      overflow: auto;
      .header {
        user-select: none;
        position: sticky;
        top: 0;
        z-index: 20;
        width: fit-content;
        color: ${(props) => props.theme.bn.gray_10};

        min-width: 100%;
        background: ${(props) => props.theme.bn.black};
        .th {
          border-bottom: 1px solid ${(props) => props.theme.bn.gray_70};
          background: ${(props) => props.theme.bn.black};
          display: flex;
          justify-content: space-between;
        }
      }

      .body {
        position: relative;
        /* z-index: 0; */
        color: ${(props) => props.theme.bn.gray_10};
        background: ${(props) => props.theme.bn.black};
        overflow: initial;
        .tr {
          &.disable {
            background: grey;
          }
        }
        .disabled {
          cursor: not-allowed;
          div {
            background: #f25e5e;
          }
        }
      }

      [data-sticky-last-left-td] {
        box-shadow: "4px 0px 6px rgba(0, 0, 0, 0.04)";
      }

      [data-sticky-first-right-td] {
        box-shadow: "4px 0px 6px rgba(0, 0, 0, 0.04)";
      }
    }
  }

  .row-buttons {
    display: flex;
  }
  .is-sticky {
    box-shadow: "4px 0px 6px rgba(0, 0, 0, 0.04)";
    border: 1.1px solid #f1f1f1;
    border-top: 0;
    border-bottom: 0;
  }
  .is-sticky-right {
    transition: 0.3s ease-in-out;
    box-shadow: -4px 0px 6px rgba(0, 0, 0, 0);
  }
  .is-sticky-left {
    transition: 0.3s ease-in-out;
    box-shadow: 4px 0px 6px rgba(0, 0, 0, 0);
  }

  &.overflow {
    .is-sticky-right {
      box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.04);
    }
    .is-sticky-left {
      box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.04);
    }
  }

  &.is-check-sticky {
    .tr {
      .th {
        width: 100%;
        :first-child {
          position: sticky;
          max-width: 62px;
          min-width: 62px;
          left: 0;
        }
      }
      .td {
        width: 100%;
        :first-child {
          position: sticky;
          max-width: 62px;
          min-width: 62px;
          min-height: 56px;
          left: 0;
        }
      }
    }
  }

  .track-horizontal {
    background: none !important;
    width: 100%;
    bottom: 0;
    z-index: 999;
  }
  .track-vertical {
    background: none !important;
    min-width: 4px;
    width: 4px;
    height: 100%;
    right: 0px;
    z-index: 999;
  }
  .thumb-horizontal {
    background: ${(props) => props.theme.bn.gray_50} !important;
    width: 4px;
    border-radius: 2px;
  }
  .thumb-vertical {
    background: ${(props) => props.theme.bn.gray_50} !important;
    width: 4px;
    border-radius: 2px;
  }
`;
