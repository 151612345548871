/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../../components/button/button";
import Select from "../../../components/input/select";
import { MainTable } from "../../../components/main-table/main-table-layout";
import RequestAdminModal from "../../../components/request-admin-modal/request-admin-modal";
import { AIR_TABLE_KEY } from "../../../env";
import { IOptions } from "../../../models/options";
import { sendMail } from "../../../services/general-services";
import getEmailTemplate from "../../../utils/email";
import { isEmpty } from "../../../utils/validations";

const HomeAdminTable: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [employees, setEmployees] = useState<any>([]);
  const [offsets, setOffsets] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [selectedAdminRequest, setSelectedAdminRequest] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    setPage(0);
    setOffsets([]);
    getWorkLeaves({ filters });
  }, [filters]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Aluxioner Name",
        accessor: "Aluxioner Name",
        sizeCol: 3,
      },
      {
        Header: "Team",
        accessor: "Team",
        sizeCol: 2,
      },
      {
        Header: "PM/PO",
        accessor: "PM/PO",
        sizeCol: 2,
      },
      {
        Header: "Dia de la petición",
        accessor: "Dia de la petición",
        sizeCol: 2,
      },
      {
        Header: "Día inicio",
        accessor: "Día inicio",
        sizeCol: 2,
      },
      {
        Header: "Día fin",
        accessor: "Día fin",
        sizeCol: 2,
      },
      {
        Header: "Tipo/Motivo",
        accessor: "Tipo/Motivo",
        sizeCol: 2,
      },
      {
        Header: "¿Aprobado Manager?",
        accessor: (row: any) => (row["¿Aprobado Manager?"] ? "Sí" : "No"),
        sizeCol: 2,
      },
      {
        Header: "¿Aprobado RRHH?",
        accessor: (row: any) => (row["¿Aprobado RRHH?"] ? "Sí" : "No"),
        sizeCol: 2,
      },
      {
        Header: "Comentario/Descripción",
        accessor: "Comentario/Descripción",
        sizeCol: 4,
      },
      {
        Header: "Justificante adjunto",
        sizeCol: 2,
        accessor: (row: any) =>
          row["Justificante adjunto"] && row["Justificante adjunto"][0] ? (
            <a
              href={row["Justificante adjunto"][0].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Abrir
            </a>
          ) : (
            "No tiene fichero"
          ),
      },
    ],
    []
  );

  const getEmployees = async () => {
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Aluxioners",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );

    const res2: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Talent",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );

    const employeesData = [
      {
        value: "",
        label: "Todos",
      },
    ].concat(
      res.data.records
        .map((item: any) => ({
          value: item.fields.Name,
          label: item.fields.Name,
        }))
        .concat(
          res2.data.records.map((item: any) => ({
            value: item.fields.Name,
            label: item.fields.Name,
          }))
        )
    );

    setEmployees(employeesData);
  };

  const getFilter = (filtersObj?: any) => {
    const filtersAux = [];
    if (filtersObj) {
      if (
        filtersObj["Tipo/Motivo"] &&
        !isEmpty(filtersObj["Tipo/Motivo"].value)
      ) {
        filtersAux.push(`{Tipo/Motivo} = '${filtersObj["Tipo/Motivo"].value}'`);
      }
      if (filtersObj.Team && !isEmpty(filtersObj.Team.value)) {
        filtersAux.push(`{Team} = '${filtersObj.Team.value}'`);
      }
      if (
        filtersObj["Aluxioner Name"] &&
        !isEmpty(filtersObj["Aluxioner Name"].value)
      ) {
        filtersAux.push(
          `FIND('${filtersObj["Aluxioner Name"].value}', {Aluxioner Name})`
        );
      }
      if (
        filtersObj["¿Aprobado Manager?"] &&
        !isEmpty(filtersObj["¿Aprobado Manager?"].value)
      ) {
        filtersAux.push(
          `{¿Aprobado Manager?} = ${
            filtersObj["¿Aprobado Manager?"].value ? "TRUE()" : "FALSE()"
          }`
        );
      }
      if (
        filtersObj["¿Aprobado RRHH?"] &&
        !isEmpty(filtersObj["¿Aprobado RRHH?"].value)
      ) {
        filtersAux.push(
          `{¿Aprobado RRHH?} = ${
            filtersObj["¿Aprobado RRHH?"].value ? "TRUE()" : "FALSE()"
          }`
        );
      }
    }
    return filtersAux.length > 0 ? `AND(${filtersAux.join(",")})` : "";
  };

  const handleFilters = (name: string, value: any) => {
    filters[name] = value;
    setFilters({ ...filters });
  };

  const getWorkLeaves = async (options?: IOptions) => {
    try {
      // Global%20status
      // Peticiones
      const newPage =
        options?.next === undefined ? 0 : options?.next ? page + 1 : page - 1;
      const res: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 10,
            offset: offsets[newPage - 1] || "",
            filterByFormula: getFilter(options?.filters),
          },
        }
      );
      let workLeaves = [];
      workLeaves = res.data.records.map((item: any) => ({
        ...item.fields,
        id: item.id,
        "Dia de la petición": moment(
          item.fields["Dia de la petición"],
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY"),
        "Día fin": moment(item.fields["Día fin"], "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        ),
        "Día inicio": moment(item.fields["Día inicio"], "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        ),
      }));

      setData(workLeaves);

      if (
        res.data.offset &&
        !offsets.includes(res.data.offset) &&
        options?.next !== false
      ) {
        setOffsets(offsets.concat([res.data.offset]));
      }
      setPage(newPage);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveRow = async (
    managerApproved: boolean,
    rrhhApproved: boolean
  ) => {
    await axios.patch(
      `https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones?api_key=${AIR_TABLE_KEY}`,
      {
        records: [
          {
            id: selectedAdminRequest.id,
            fields: {
              "¿Aprobado Manager?": managerApproved,
              "¿Aprobado RRHH?": rrhhApproved,
            },
          },
        ],
      }
    );
    if (managerApproved && rrhhApproved) {
      const aluxionerMail = selectedAdminRequest["Aluxioner Name"]
        .split("(")[1]
        .replace(")", "");
      sendEmail(
        aluxionerMail,
        "¡Hola Aluxioner!",
        `Te informamos de que tus vacaciones del ${selectedAdminRequest["Día inicio"]} al ${selectedAdminRequest["Día fin"]} han sido aprobadas 🎉.`
      );
    }
    setSelectedAdminRequest(null);
    getWorkLeaves();
  };

  const handleCancelRequest = async () => {
    await axios.patch(
      `https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones?api_key=${AIR_TABLE_KEY}`,
      {
        records: [
          {
            id: selectedAdminRequest.id,
            fields: {
              "Canceladas?": true,
            },
          },
        ],
      }
    );
    const aluxionerMail = selectedAdminRequest["Aluxioner Name"]
      .split("(")[1]
      .replace(")", "");
    sendEmail(
      aluxionerMail,
      "¡Upss!",
      `Te informamos de que tus vacaciones del ${selectedAdminRequest["Día inicio"]} al ${selectedAdminRequest["Día fin"]} han sido canceladas.`
    );
    setSelectedAdminRequest(null);
    getWorkLeaves();
  };

  const sendEmail = async (to: string, title: string, body: string) => {
    const args = {
      to,
      html: getEmailTemplate(title, body),
      from: "rrhh@aluxion.com",
      subject: "Aluxion | Vacations 🌴",
    };
    await sendMail(args);
  };

  return (
    <>
      <div className="home-filter-container">
        <div className="home-filter-item">
          <Select
            label={"Nombre"}
            value={filters["Aluxioner Name"]}
            onChange={(option: any) => handleFilters("Aluxioner Name", option)}
            options={employees}
            placeholder="Seleccione Aluxioner"
            customEmpty="Escribe un nombre"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"Team"}
            value={filters.Team}
            onChange={(option: any) => handleFilters("Team", option)}
            options={[
              { className: "all", label: "Todos", value: "" },
              {
                className: "frontend",
                label: "Frontend",
                value: "Frontend",
              },
              {
                className: "backend",
                label: "Backend",
                value: "Backend",
              },
              { className: "po-pm", label: "PO/PMs", value: "PO/PMs" },
              { className: "design", label: "Design", value: "Design" },
              {
                className: "marketing",
                label: "Marketing",
                value: "Marketing",
              },
              { className: "rrhh", label: "RRHH", value: "RRHH" },
              { className: "qa", label: "QA", value: "QA" },
              {
                className: "operations",
                label: "Operations",
                value: "Operations",
              },
            ]}
            placeholder="Seleccione Team"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"Tipo/Motivo"}
            value={filters["Tipo/Motivo"]}
            onChange={(option: any) => handleFilters("Tipo/Motivo", option)}
            options={[
              { label: "Todos", value: "" },
              { label: "Vacaciones", value: "Vacaciones" },
              { label: "Mudanza", value: "Mudanza" },
              { label: "Baja", value: "Baja" },
            ]}
            placeholder="Seleccione Tipo/Motivo"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"¿Aprobado Manager?"}
            value={filters["¿Aprobado Manager?"]}
            onChange={(option: any) =>
              handleFilters("¿Aprobado Manager?", option)
            }
            options={[
              { label: "Todos", value: "" },
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            placeholder="¿Aprobado Manager?"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"¿Aprobado RRHH?"}
            value={filters["¿Aprobado RRHH?"]}
            onChange={(option: any) => handleFilters("¿Aprobado RRHH?", option)}
            options={[
              { label: "Todos", value: "" },
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            placeholder="¿Aprobado RRHH?"
          />
        </div>
        {/* <div className="home-filter-item">
              <Button type="ghost" text="Buscar" onClick={() => getWorkLeaves({ offset, filters })} />
            </div> */}
        <div className="home-filter-item__button">
          <Button type="ghost" text="Limpiar" onClick={() => setFilters({})} />
        </div>
      </div>
      <div className="dot-circle-container">
        {Object.values(filters).map((filterValue: any) =>
          filterValue.value === "" ? "" : <div className="dot-circle" />
        )}
      </div>
      <MainTable
        data={data}
        columns={columns}
        onRowClick={(row) => setSelectedAdminRequest(row.original)}
      />
      <div className="table-buttons">
        <div className="table-buttons__item">
          <Button
            size="small"
            type="ghost"
            text="Anterior"
            disabled={page <= 0}
            onClick={() => getWorkLeaves({ next: false })}
          />
        </div>
        <div className="table-buttons__item">
          <Button
            size="small"
            type="ghost"
            text="Siguiente"
            disabled={page >= offsets.length}
            onClick={() => getWorkLeaves({ next: true })}
          />
        </div>
      </div>
      {selectedAdminRequest && !selectedAdminRequest["Canceladas?"] && (
        <RequestAdminModal
          name={selectedAdminRequest["Aluxioner Name"]}
          requestDate={selectedAdminRequest["Dia de la petición"]}
          startDate={selectedAdminRequest["Día inicio"]}
          finishDate={selectedAdminRequest["Día fin"]}
          poName={selectedAdminRequest["PM/PO"]}
          team={selectedAdminRequest.Team}
          type={selectedAdminRequest["Tipo/Motivo"]}
          managerApproved={selectedAdminRequest["¿Aprobado Manager?"]}
          rrhhApproved={selectedAdminRequest["¿Aprobado RRHH?"]}
          onClose={() => setSelectedAdminRequest(null)}
          onCancel={() => handleCancelRequest()}
          onSave={(managerApproved, rrhhApproved) =>
            handleSaveRow(managerApproved, rrhhApproved)
          }
        />
      )}
    </>
  );
};

export default HomeAdminTable;
