import React from "react";
import classnames from "classnames";
import { ButtonStyle, ButtonIconStyle } from "./button-style";
import SVGIcon from "../svg-icon/svg-icon";

interface IButtonIcon {
  src?: string;
  color?: string;
}

export interface IButtonProps {
  onClick: (ev?: any) => void;
  text?: string;
  size?: "large" | "medium" | "small";
  inGroup?: boolean;
  className?: string;
  disabled?: boolean;
  type?:
  | "red"
  | "primary"
  | "secondary"
  | "ghost"
  | "hight-contrast"
  | "danger"
  | "dark-primary"
  | "dark-secondary"
  | "dark-tertiary"
  | "dark-hight-contrast"
  | "dark-hight-contrast-white"
  | "filter"
  | "filter-active";
  singleIcon?: string;
  leftIcon?: string;
  rightIcon?: string;
  rightIconColor?: string;
  leftIconColor?: string;
  count?: number;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const {
    text,
    type,
    leftIcon,
    rightIcon,
    onClick,
    disabled,
    className,
    singleIcon,
    size,
    inGroup,
    rightIconColor,
    leftIconColor,
    count,
  } = props;

  return (
    <>
      {!singleIcon ? (
        <ButtonStyle
          data-testid="button"
          className={classnames(className || "", type || "", size || "", {
            disabled: !!disabled,
            "in-group": inGroup,
          })}
          onClick={(ev: any) => {
            if (!disabled && onClick) onClick(ev);
          }}
        >
          {count && (
            <div className="button-left-count">
              <p>{count}</p>
            </div>
          )}
          {leftIcon && (
            <div className="button-left-icon">
              <SVGIcon color={leftIconColor} size="24px" icon={leftIcon} />
            </div>
          )}
          {text && (
            <div className="button-text">
              <p>{text}</p>
            </div>
          )}
          {rightIcon && (
            <div className="button-right-icon">
              <SVGIcon color={rightIconColor} size="24px" icon={rightIcon} />
            </div>
          )}
        </ButtonStyle>
      ) : (
          <ButtonIconStyle
            className={classnames(className || "", type || "", {
              disabled: !!disabled,
            })}
            onClick={() => (!disabled && onClick ? onClick() : "")}
          >
            <div className="button-icon">
              <SVGIcon size="16px" icon={singleIcon} />
            </div>
          </ButtonIconStyle>
        )}
    </>
  );
};

export default Button;
