import React, { useState } from "react";
import { ModalLoginStyle } from "./modal-login-style";
import GoogleLogin from "react-google-login";
import Button from "../button/button";
import { FavLogoBlack } from "../../assets/icons/icons";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import { Aluxion, AluxionWhite, LoginWhite, LoginBlack } from "../../assets/images/images";

interface IModalLoginProps {
  onUserEmail: (email: string) => void;
}

const ModalLogin = (props: IModalLoginProps) => {
  const { isDarkMode } = useDarkMode();
  const [logged, setLogged] = useState(localStorage.getItem("logged") === "true");
  const [, setError] = useState(false);

  const handleLogin = (resp: any) => {
    if (
      resp.profileObj.email.indexOf("@aluxion.com") > -1 ||
      resp.profileObj.email.indexOf("@external.aluxion.com") > -1
    ) {
      localStorage.setItem("loggedEmail", resp.profileObj.email);
      props.onUserEmail(resp.profileObj.email);
      setLogged(true);
    } else {
      setError(true);
    }
  };

  return (
    <ModalLoginStyle className={logged ? "logged" : ""}>
      <div className="header">
        <div className="header__logo">
          <img src={!isDarkMode ? AluxionWhite : Aluxion} alt="aluxion" />
        </div>
        <div className="header__title">vacations</div>
      </div>
      <div className="modal-login-wrapper">
        <GoogleLogin
          clientId="982014724948-0hc14ollnjgdrn62vnkj1l1h34j9ogtu.apps.googleusercontent.com"
          render={(renderProps) => (
            <Button onClick={renderProps.onClick} text={"Login con Gmail"} type={"ghost"} leftIcon={FavLogoBlack} />
          )}
          buttonText="Login"
          onSuccess={(resp) => handleLogin(resp)}
          onFailure={(err) => console.log(err)}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        />
      </div>
      <div className="modal-login-background">
        <img src={!isDarkMode ? LoginWhite : LoginBlack} alt="" />
      </div>
    </ModalLoginStyle>
  );
};

export default ModalLogin;
