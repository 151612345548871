import { createGlobalStyle } from "styled-components";

export const ResetStyles = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  word-break: break-word;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html * {
  box-sizing: border-box;
}

body {
  font-family: "norms", sans-serif;
  transition: 0.3s ease-in-out all;
  background: ${(props) => props.theme.bn.black};
  color:${(props) => props.theme.bn.white};
}


a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
  width: 100%;
}

input {
  -webkit-appearance: none;
  border: none;
  background: none;
}

input,
button {
  &:focus {
    outline: unset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: none !important;
  color: inherit;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

button {
  margin: 0;
}

.track-horizontal {
    background: none !important;
    width: 100%;
    bottom: 2px;
    z-index: 999;
  }
  .track-vertical {
    background: none !important;
    min-width: 4px;
    width: 4px;
    height: 100%;
    right: 2px;
    z-index: 999;
  }
  .thumb-horizontal {
    background: ${(props) => props.theme.bn.gray_50} !important;
    width: 4px;
    border-radius: 2px;
  }
  .thumb-vertical {
    background: ${(props) => props.theme.bn.gray_50} !important;
    width: 4px;
    border-radius: 2px;
  }


  .rbc-overlay {
    background: ${(props) => props.theme.bn.black} !important;
    color: ${(props) => props.theme.bn.white};
    padding: 12px;
    .rbc-overlay-header { 
      margin-bottom: 12px;
      padding: 8px 10px;
    }
    .rbc-event {
      margin-bottom: 4px;
    }
  }

  .front-team {
  background: #3c96b4 !important;
}

.back-team {
  background: #3aa537 !important;
}

.management-team {
  background: #8c5ac8 !important;
}

.design-team {
  background: #ffd048 !important;
}

.marketing-team {
  background: #e5546c !important;
}

.rrhh-team {
  background: #faa082 !important;
}

.qa-team {
  background: #000000 !important;
}

.operations-team {
  background: #e677d7 !important;
}

`;

export default ResetStyles;
