import React from "react";

import { ZeroContentStyle } from "./zero-content-style";
import Button, { IButtonProps } from "../button/button";

export interface IZeroContentProps {
  icon: string;
  text: string;
  className?: string;
  button?: IButtonProps;
}

const ZeroContent: React.FC<IZeroContentProps> = (props: IZeroContentProps) => {
  const { icon, text, className, button } = props;
  return (
    <ZeroContentStyle className={className}>
      <div className="zero-content-icon">
        <img src={icon} alt="" />
      </div>
      <div className="zero-content-text">
        <p>{text}</p>
      </div>
      {button && (
        <div className="zero-content-button">
          <Button {...button} />
        </div>
      )}
    </ZeroContentStyle>
  );
};

export default ZeroContent;
