import styled from "styled-components";

export const ModalLoginStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.bn.black};
  backdrop-filter: saturate(180%) blur(10px);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-login-background {
    position: absolute;
    bottom: -100px;
    right: -100px;
    width: 60%;
    opacity: 0.3;
    z-index: -1;
  }

  .header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 32px 60px;
    display: flex;
    z-index: 99;
    align-items: center;

    &__title {
      margin-left: 24px;
      padding-left: 24px;
      font-weight: 400;
      font-size: 28px;
      font-family: "hall";
      border-left: 1px solid ${(props) => props.theme.bn.gray_50};
    }
  }

  .modal-login-wrapper {
    border-radius: 4px;
    padding: 30px;
    &__input {
      height: 80px;
    }
    &__button {
    }
  }

  &.logged {
    display: none;
  }
`;
