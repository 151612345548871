import React, { useEffect, useState } from "react";
import { RequestAdminModalStyle } from "./request-admin-modal-style";
import { Close } from "../../assets/icons/icons";
import SVGIcon from "../svg-icon/svg-icon";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import Button from "../button/button";

interface IRequestAdminModalProps {
  name: string;
  requestDate: string;
  startDate: string;
  finishDate: string;
  poName: string;
  team: string;
  type: string;
  onClose: () => void;
  onSave: (managerApproved: boolean, rrhhApproved: boolean) => void;
  onCancel: () => void;
  managerApproved: boolean;
  rrhhApproved: boolean;
}

const RequestAdminModal = (props: IRequestAdminModalProps) => {
  const {
    name,
    requestDate,
    startDate,
    finishDate,
    poName,
    team,
    type,
    onClose,
    managerApproved,
    rrhhApproved,
    onSave,
    onCancel,
  } = props;

  const { isDarkMode } = useDarkMode();

  const [managerApprovedState, setManagerApprovedState] = useState(managerApproved);
  const [rrhhApprovedState, setRrhhApprovedState] = useState(rrhhApproved);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    document.body.style.overflowY = "scroll";
    onClose();
  };

  return (
    <RequestAdminModalStyle onClick={() => handleClose()}>
      <div className="request-admin-modal-content" onClick={(ev) => ev.stopPropagation()}>
        <div className="top-close" onClick={() => handleClose()}>
          <SVGIcon size="32px" icon={Close} color={isDarkMode ? "#040404" : "#ffffff"} />
        </div>
        <div className="request-admin-modal-content__name">
          <p>{name}</p>
        </div>
        <div className="request-admin-modal-content__team">
          <p>{team}</p>
        </div>
        <div className="request-admin-modal-content__type">
          <p>
            Tipo:<span>{type}</span>
          </p>
        </div>
        <div className="request-admin-modal-content__type">
          <p>
            {" "}
            Pedidas el:<span>{requestDate}</span>
          </p>
        </div>
        <div className="request-admin-modal-content__po">
          <p>
            PM/PO: <span>{poName}</span>
          </p>
        </div>
        <div className="request-admin-modal-content__duration">
          <div className="request-admin-modal-content__duration__text">
            <p>Duración:</p>
          </div>
          <div className="request-admin-modal-content__duration__start">
            <p>{startDate}</p>
          </div>
          <div className="request-admin-modal-content__duration__space">
            <p>-</p>
          </div>
          <div className="request-admin-modal-content__duration__end">
            <p>{finishDate}</p>
          </div>
        </div>
        {(!rrhhApproved || !managerApproved) && (
          <div className="request-admin-modal-content__status">
            <div className="request-admin-modal-content__status__item">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={rrhhApprovedState}
                  onClick={() => setRrhhApprovedState(!rrhhApprovedState)}
                />
                <span className="slider round" />
              </label>
              <div>{"Aprobar RRHH"}</div>
            </div>
            <div className="request-admin-modal-content__status__item">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={managerApprovedState}
                  onClick={() => setManagerApprovedState(!managerApprovedState)}
                />
                <span className="slider round" />
              </label>
              <div>{"Aprobar manager"}</div>
            </div>
          </div>
        )}
        <div className="request-admin-modal-content__buttons">
          {(!rrhhApproved || !managerApproved) && (
            <div className="request-admin-modal-content__button">
              <Button text={"Cancelar Petición"} type={"danger"} onClick={() => onCancel()} />
            </div>
          )}
          <div className="request-admin-modal-content__button">
            <Button
              text={!rrhhApproved || !managerApproved ? "Guardar" : "Cancelar Petición"}
              type={!rrhhApproved || !managerApproved ? "secondary" : "danger"}
              onClick={() =>
                !rrhhApproved || !managerApproved ? onSave(managerApprovedState, rrhhApprovedState) : onCancel()
              }
            />
          </div>
        </div>
      </div>
    </RequestAdminModalStyle>
  );
};

export default RequestAdminModal;
