import { DefaultTheme } from "styled-components";

interface IColorBrand {
  [propName: string]: string;
  deep_blue: string;
  electric_blue: string;
  cyan: string;
}
interface IColorData {
  [propName: string]: string;
  blue_1: string;
  blue_2: string;
  blue_3: string;
  blue_4: string;
  pale_electric_blue: string;
  turquoise: string;
  pale_blue: string;
  purple: string;
  pink: string;
  red: string;
  orange: string;
}
interface IColorBN {
  [propName: string]: string;
  black: string;
  gray_80: string;
  gray_70: string;
  gray_50: string;
  gray_30: string;
  gray_18: string;
  gray_10: string;
  gray_06: string;
  white: string;
}
interface IColorUI {
  [propName: string]: string;
  gray_80: string;
  gray_70: string;
  gray_50: string;
  gray_white: string;
  deep_blue: string;
  deep_electric_blue: string;
  deep_cyan: string;
  red: string;
  yellow: string;
  green: string;
}
interface IColorState {
  [propName: string]: string;
  dark_neutral: string;
  dark_blue: string;
  dark_red: string;
  dark_green: string;
  dark_yellow: string;
}
interface IColorOpacity {
  [propName: string]: string;
  dark_neutral: string;
  dark_blue: string;
  green: string;
  red: string;
  yellow: string;
}

declare module "styled-components" {
  export interface DefaultTheme {
    brand: IColorBrand;
    data: IColorData;
    bn: IColorBN;
    ui: IColorUI;
    states: IColorState;
    opacity: IColorOpacity;
  }
}

export const darkTheme: DefaultTheme = {
  brand: {
    deep_blue: "#051C2C",
    electric_blue: "#2251FF",
    cyan: "#00A9F4",
  },
  data: {
    blue_1: "#034B6F",
    blue_2: "#027AB1",
    blue_3: "#39BDF3",
    blue_4: "#71D2F1",
    pale_electric_blue: "#AFC3FF",
    turquoise: "#3C96B4",
    pale_blue: "#AAE6F0",
    purple: "#8C5AC8",
    pink: "#E6A0C8",
    red: "#E5546C",
    orange: "#FAA082",
  },
  bn: {
    black: "#FFFFFF",
    gray_80: "#F0F0F0",
    gray_70: "#E6E6E6",
    gray_50: "#D0D0D0",
    gray_30: "#B3B3B3",
    gray_18: "#7F7F7F",
    gray_10: "#4D4D4D",
    gray_06: "#333333",
    white: "#040404",
  },
  ui: {
    gray_80: "#333333",
    gray_70: "#4D4D4D",
    gray_50: "#7F7F7F",
    gray_white: "#FFFFFF",
    deep_blue: "#051C2C",
    deep_electric_blue: "#2251FF",
    deep_cyan: "#00A9F4",
    red: "#E12E40",
    yellow: "#FFD048",
    green: "#3AA537",
  },
  states: {
    dark_neutral: "#1B1B19",
    dark_blue: "#1E32E6",
    dark_red: "#B0001D",
    dark_green: "#006200",
    dark_yellow: "#6A4F00",
  },
  opacity: {
    dark_neutral: "#EBEBF6",
    dark_blue: "#E6ECFF",
    green: "#D1F4DD",
    red: "#FFE5EA",
    yellow: "#FFF3CE",
  }
};





