// tslint:disable: jsx-key
import React, { useEffect, useRef, useState } from "react";
import { useBlockLayout, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSelection } from "./hooks/useSelection";
import { useSticky } from "./hooks/useSticky";
import { MainTableContainer } from "./main-table-style";
import { MainTableProps } from "./types";
import ZeroContent from "../zero-content/zero-content";
import Pagination from "../pagination/pagination";
import SVGIcon from "../svg-icon/svg-icon";

export const MainTable: React.FC<MainTableProps> = ({
  columns,
  data,
  selection,
  multipleSelection,
  handleCheck,
  handleOrder,
  initialSelection,
  paginate,
  totalPages,
  onPaginate,
  zeroContent,
  selectedCell,
  onRadioCheck,
  disableSort,
  noScroll,
  onRowClick,
}) => {
  const TABLE_OPTIONS = {
    columns,
    data,
    handleOrder,
    multipleSelection,
    selection,
    onRadioCheck,
    manualSortBy: true,
    manualPagination: true,
    pageCount: totalPages,
    disableSortBy: disableSort || false,
  };

  const mainTable: any = useRef();
  const tableBody: any = useRef();
  const [overflow, setOverflow] = useState<boolean>(false);

  const getWidthColumn = (column: any) => {
    let cellSize: number = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let offsetColumn: number = 0;

    if (!column.sizeCol) {
      offsetColumn = column.width;
    }

    if (mainTable && mainTable.current && column.sizeCol) {
      cellSize = (mainTable.current.offsetWidth / 16) * column.sizeCol;
      return {
        minWidth: cellSize > 132 ? cellSize : 132,
        width: "100%",
        // width: cellSize > 132 ? cellSize : 132,
        // maxWidth: cellSize > 132 ? cellSize : 132,
        display: "flex",
        alignItems: "center",
      };
    }

    return {
      minWidth: column.width,
      width: column.width ? column.width : "100%",
      display: "flex",
      alignItems: "center",
    };
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { selectedRowIds, sortBy, pageIndex },
  }: any = useTable(TABLE_OPTIONS, useSortBy, useRowSelect, useSticky, useBlockLayout, usePagination, useSelection);

  useEffect(() => {
    if (handleOrder) {
      let order = null;
      if (sortBy.length > 0) {
        order = "";
        const columnToSort = sortBy[0];
        if (columnToSort) {
          const operator = columnToSort.desc ? "-" : "+";
          order = `${operator}${columnToSort.id}`;
        }
      }
      handleOrder(order);
    }
  }, [handleOrder, sortBy]);

  useEffect(() => {
    const detectOverflow = () => {
      const mainTableWidth = mainTable.current && mainTable.current.offsetWidth;
      setOverflow(columns.length * 200 > mainTableWidth);
    };

    setTimeout(() => {
      detectOverflow();
    }, 1000);
    window.addEventListener("resize", () => detectOverflow());
    return window.removeEventListener("resize", () => detectOverflow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleCheck) {
      const rowsIdsSelected = Object.keys(selectedRowIds);
      const originalIdsSelected = rows
        .filter((row: any) => rowsIdsSelected.includes(row.id))
        .map((r: any) => r.original.id);

      handleCheck(originalIdsSelected);
    }

    if (selectedCell) {
      rows.forEach((row: any) => {
        row.toggleRowSelected(row.original.id === selectedCell);
      });
    }
  }, [selectedRowIds, selectedCell, handleCheck, rows]);

  useEffect(() => {
    if (initialSelection && initialSelection.data) {
      const selectedIDS = initialSelection.data;
      for (const row of rows) {
        let rowValueToCompare = row.original.id;
        if (initialSelection.key) {
          if (!row.original[initialSelection.key]) {
            throw new Error(`[TableInitSelection] Key ${initialSelection.key} not found on entity`);
          }

          if (!Array.isArray(row.original[initialSelection.key])) {
            throw new Error(`[TableInitSelection] Entity data must be an array.`);
          }

          rowValueToCompare = row.original[initialSelection.key].map((entity: any) => entity.id);
        }

        selectedIDS.forEach((selectedID) => {
          if (Array.isArray(rowValueToCompare)) {
            if (rowValueToCompare.includes(selectedID)) {
              row.toggleRowSelected(true);
            }
          } else {
            if (row.original.id === selectedID) {
              row.toggleRowSelected(true);
            }
          }
        });
      }
    }
  }, [initialSelection, rows]);

  const emptyMessageDefault = "¡Oops! No hemos encontrado ningún resultado, prueba con otra búsqueda";

  return rows.length < 1 ? (
    <ZeroContent icon="" text={emptyMessageDefault} {...zeroContent} />
  ) : (
    <>
      <MainTableContainer
        ref={mainTable}
        className={`${overflow ? "overflow" : ""}
        ${selection || multipleSelection ? "is-check-sticky" : ""}
      
        `}
      >
        <div className={`table sticky ${noScroll ? "no-scroll" : ""} `} {...getTableProps()} style={{ width: "100%" }}>
          {/* <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
            renderTrackVertical={(props) => <div {...props} className="track-vertical" />}
            renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
            autoHeight={true}
            style={{ width: "100%", height: "100%" }}
          > */}
          <div className="header">
            {headerGroups.map((headerGroup: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any) => {
                  return (
                    <div
                      {...column.getHeaderProps([
                        column.getSortByToggleProps(),
                        {
                          className: `th ${column.className}`,
                          style: getWidthColumn(column),
                        },
                      ])}
                    >
                      {["selection", "multiple-selection"].includes(column.id) ? (
                        <>{column.render("Header")}</>
                      ) : (
                        <p>{column.render("Header")}</p>
                      )}
                      {column.canSort && (
                        <span>
                          <SVGIcon
                            size="16px"
                            color="#ffffff"
                            icon={
                              ""
                              // column.isSorted
                              //   ? column.isSortedDesc
                              //     ? icons.arrows.chevron_down
                              //     : icons.arrows.chevron_up
                              //   : icons.arrows.enlarge
                            }
                          />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body" ref={tableBody}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  className={`tr ${row.original["Canceladas?"] ? "disabled" : ""}`}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <div
                        {...cell.getCellProps({
                          className: `td ${cell.column.className}`,
                          style: getWidthColumn(cell.column),
                        })}
                      >
                        {["selection", "multiple-selection"].includes(cell.column.id) || cell.column.custom ? (
                          <>{cell.render("Cell")}</>
                        ) : (
                          <p>{cell.render("Cell")}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {/* </Scrollbars> */}
        </div>
      </MainTableContainer>
      {paginate && pageCount > 1 && (
        <div className="table-wrapper-navigation">
          <Pagination
            page={pageIndex}
            hasPrevious={canPreviousPage}
            hasNext={canNextPage}
            handleNext={nextPage}
            handlePrevious={previousPage}
            goTo={gotoPage}
            limit={pageCount}
            onClick={onPaginate && ((page: number) => onPaginate(page))}
          />
        </div>
      )}
    </>
  );
};
