import classnames from "classnames";
import React, { useEffect, useRef } from "react";
import { InputStyle, SelectContainerStyle, SelectStyle } from "./input-style";
import { ChevronDown } from "../../assets/icons/icons";
import SVGIcon from "../svg-icon/svg-icon";

import { lightTheme } from "../../assets/colors/lightTheme";
import { darkTheme } from "../../assets/colors/darkTheme";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export interface SelectProps {
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  onChangeInput?: any;
  multiple?: boolean;
  menuIsOpen?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  message?: string;
  errorMessage?: string;
  tooltip?: string;
  label?: string;
  customEmpty?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  value?: any;
  options: { value: any; label: string; icon?: any;[key: string]: any }[];
}

const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const { isDarkMode } = useDarkMode();
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>(props.multiple ? [] : "");
  const [options, setOptions] = React.useState<any[]>(props.options && props.options.length > 0 ? props.options : []);
  const [focus, setFocus] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const toggleContainer = useRef(null);
  const first = useRef<boolean>(true);

  useEffect(() => {
    const val = props.value ? props.value : props.multiple ? [] : "";
    if (!first.current && val.toString() !== value.toString()) {
      setValue(val);
    }
    first.current = false;
  }, [props.multiple, props.value, value]);

  useEffect(() => {
    if (Array.isArray(props.options)) {
      setOptions(props.options);
    }
  }, [props.options]);

  const onClickOutsideHandler = () => {
    if (open) {
      handleOpen();
    }
  };

  const handleOpen = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    if (!open) {
      setOptions(props.options.map((o: any) => o));
    }
    if (!props.disabled) {
      setOpen(!open);
    }
  };

  const handleChange = (ev: any) => {
    if (props.onChangeInput) {
      props.onChangeInput(ev);
    } else {
      const val = ev.target.value
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const optionsNew = props.options.filter(
        (item: any) =>
          item.label
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .indexOf(val) > -1,
      );

      setOptions(optionsNew);
    }
  };

  const handleSelectedItem = (option: any) => {
    const auxValue: any = JSON.parse(JSON.stringify(props.value || value));
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    setOptions(props.options.map((o: any) => o));

    if (props.multiple) {
      auxValue.push(option);
      setValue(auxValue);
    } else {
      setOpen(false);
      setValue(option);
    }
    if (props.onChange) {
      props.onChange(props.multiple ? auxValue : option);
    }
  };

  const removeItem = (index: number) => {
    const val: any = JSON.parse(JSON.stringify(props.value || value));

    val.splice(index, 1);

    if (props.onChange) {
      props.onChange(val);
    }
    setValue(val);
  };

  const renderSelectedItems = () => {
    const val = props.value || value;

    if (!props.multiple) {
      if (Object.keys(val).length === 0) return;
      return <div className={classnames("selected-item ", { multiple: props.multiple })}>{val.label}</div>;
    }

    return val.map((v: any, index: number) => (
      <div
        onClick={() => removeItem(index)}
        key={`item-${v.value}`}
        className={classnames("selected-item ", { multiple: props.multiple })}
      >
        {v.label}
      </div>
    ));
  };

  const isSelected = (option: any) => {
    const val = props.value || value;
    if (props.multiple) {
      return val.findIndex((op: any) => JSON.stringify(option) === JSON.stringify(op)) > -1;
    }
    return JSON.stringify(option) === JSON.stringify(val);
  };

  useOutsideClick(toggleContainer, onClickOutsideHandler);

  return (
    <SelectContainerStyle>
      <InputStyle
        ref={toggleContainer}
        className={classnames(props.className || "", {
          error: !!props.error,
          disabled: !!props.disabled,
          required: !!props.required,
          "on-focus": focus,
          complete: Object.keys(props.value || value).length,
        })}
      >
        {(props.label || props.tooltip) && (
          <div className="input-top">
            {props.label && (
              <div className="input-top-label">
                <p>{props.label}</p>
              </div>
            )}
            {props.tooltip && <div className="input-top-tooltip">{props.tooltip}</div>}
          </div>
        )}
        <div className="input-body">
          {/* <div className="input-body-multiple-selected">{renderSelectedItems()}</div> */}
          <div className="input-body-selected" onClick={() => handleOpen()}>
            {renderSelectedItems()}
          </div>
          <div className="input-body-wrapper" onClick={() => handleOpen()}>
            <input
              autoComplete="nope"
              ref={inputRef}
              type="text"
              name={props.name}
              placeholder={Object.keys(props.value || value).length !== 0 ? "" : props.placeholder}
              disabled={props.disabled}
              required={props.required}
              onChange={(ev: any) => !props.disabled && handleChange(ev)}
              onFocus={(ev: any) => {
                if (props.disabled) return;
                setFocus(true);
                return props.onFocus && props.onFocus(ev);
              }}
              onBlur={(ev: any) => {
                if (props.disabled) return;
                setFocus(false);
                setTimeout(() => handleOpen(), 200);
                return props.onBlur && props.onBlur(ev);
              }}
              onKeyDown={(ev: any) => !props.disabled && props.onKeyDown && props.onKeyDown(ev)}
            />
            {/*  */}
            <div className="input-body-selected">{renderSelectedItems()}</div>

            <div className="input-body-icon arrow-select">
              <SVGIcon icon={ChevronDown} color={isDarkMode ? lightTheme.bn.gray_30 : darkTheme.bn.gray_70} />
            </div>
          </div>
          <SelectStyle className={classnames({ open: open || props.menuIsOpen })}>
            {options.length > 0 ? (
              options.map((option: any) => (
                <div
                  key={`list-${option.value}`}
                  className={classnames("select-item", option.className && option.className, {
                    "custom-item": option.className!!,
                    multiple: props.multiple,
                    selected: isSelected(option),
                  })}
                  onClick={() => handleSelectedItem(option)}
                >
                  <p>{option.label}</p>
                  {option.icon && <img src={option.icon} alt="icon-select" />}
                </div>
              ))
            ) : (
                <div className="select-item disable">
                  <p>{props.customEmpty ? props.customEmpty : "No hay opciones"}</p>
                </div>
              )}
          </SelectStyle>
        </div>

        <div className="input-bottom">
          {props.errorMessage && (
            <div className="input-bottom-error">
              <p>{props.errorMessage}</p>
            </div>
          )}
          {props.message && (
            <div className="input-bottom-message">
              <p>{props.message}</p>
            </div>
          )}
        </div>
      </InputStyle>
    </SelectContainerStyle>
  );
};

export default Select;
