import classnames from "classnames";
import Cleave from "cleave.js/react";
import { format, isAfter, parse } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CalendarWhite } from "../../assets/images/images";
import Calendar from "../calendar/calendar";
import SVGIcon from "../svg-icon/svg-icon";
// import Tooltip from "../tooltip/tooltip";
import { InputCalendarStyle, InputStyle } from "./input-style";

const isValidDate = (date: string): boolean =>
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(date);

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export interface InputCalendarProps {
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  onBlurRangeSelect?: any;
  onFocusRangeSelect?: any;
  onKeyDownRangeSelect?: any;
  onSelectItem?: any;
  menuIsOpen?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  message?: string;
  errorMessage?: string;
  tooltip?: string;
  label?: string;
  placeholder?: string;
  placeholderRangeSelect?: string;
  name?: string;
  nameRangeSelect?: string;
  className?: string;
  value?: any;
  forbidFutureDates?: boolean;
  forbidPastDates?: boolean;
  rangeSelect?: boolean;
  minAge?: number;
  maxAge?: number;
  classNameCalendar?: string;
  multiSelect?: boolean;
  icon?: boolean;
}

const InputCalendar: React.FC<InputCalendarProps> = (
  props: InputCalendarProps
) => {
  const getValue = () => {
    if (props.rangeSelect || props.multiSelect) {
      if (props.value && Array.isArray(props.value)) {
        return props.value.map((v: any) => format(v, "dd/MM/yyyy"));
      }
      return [];
    }
    if (props.value) {
      return format(props.value, "dd/MM/yyyy");
    }
    return "";
  };

  const first = useRef(true);
  const [open, setOpen] = useState<boolean>(false);
  const [valueString, setValueString] = useState<string>("");
  const [valueString2, setValueString2] = useState<string>("");
  const [value, setValue] = useState<any>(getValue());
  const inputRef = useRef(null);
  const inputRefRangeSelect = useRef(null);
  const toggleContainer = useRef(null);

  useEffect(() => {
    const values = getValue();

    if (props.rangeSelect) {
      setValueString(values[0] || "");
      setValueString2(values[1] || "");
    } else if (!props.multiSelect) {
      setValueString(values.toString());
    }
    setValue(values);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (props.onBlur && !first.current) {
      if (Array.isArray(value)) {
        props.onBlur(value.map((v) => parse(v, "dd/MM/yyyy", new Date())));
      } else {
        props.onBlur(value ? parse(value, "dd/MM/yyyy", new Date()) : "");
      }
    }
    if (first.current) {
      first.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const validationBetweenDates = (firstDate: string, secondDate: string) => {
    const isItMinor = isAfter(
      parse(firstDate, "dd/MM/yyyy", new Date()),
      parse(secondDate, "dd/MM/yyyy", new Date())
    );
    if (isItMinor) {
      // tslint:disable-next-line: no-unused-expression
      secondDate && setValueString(secondDate);
      setValueString2(firstDate);
    } else {
      setValueString(firstDate);
      // tslint:disable-next-line: no-unused-expression
      secondDate && setValueString2(secondDate);
    }
  };

  const onClickOutsideHandler = () => {
    if (open) {
      handleOpen();
      handleOnBlur();
    }
  };

  const handleOpen = () => {
    const input = inputRef ? inputRef.current : null;
    const inputRangeSelect = inputRefRangeSelect
      ? inputRefRangeSelect.current
      : null;
    if (
      !open ||
      (document.activeElement !== ReactDOM.findDOMNode(input) &&
        document.activeElement !== ReactDOM.findDOMNode(inputRangeSelect))
    ) {
      setOpen(!open);
    }
  };

  const handleOnBlur = () => {
    if (props.rangeSelect) {
      handleRangeSelect();
    } else if (!props.multiSelect) {
      handleOneDate();
    }
  };

  const handleOnKeyDown = (ev: any) => {
    if (props.onKeyDown) {
      props.onKeyDown(ev);
    }
    if (ev.key === "Enter" && props.multiSelect) {
      handleMultiSelect();
    }
  };

  const handleOneDate = () => {
    if (isValidDate(valueString)) {
      setValue(valueString);
    } else {
      setValueString(value);
    }
  };

  const handleRangeSelect = () => {
    if (!isValidDate(valueString)) {
      setValueString(value[0] || "");
    } else if (!isValidDate(valueString2)) {
      setValueString2(value[1] || "");
    } else {
      validationBetweenDates(valueString, valueString2);
      setValue([valueString, valueString2]);
    }
  };

  const handleMultiSelect = () => {
    if (isValidDate(valueString)) {
      const valueCopy = JSON.parse(JSON.stringify(value));
      valueCopy.push(valueString);
      setValue(valueCopy);
    }
    setValueString("");
  };

  const handleOnSelect = (
    // tslint:disable-next-line:no-shadowed-variable
    value: string,
    close: boolean,
    secondValue?: string
  ) => {
    if (secondValue) {
      validationBetweenDates(value, secondValue);
    } else {
      setValueString(value);
    }

    if (props.multiSelect || props.rangeSelect) {
      setValue([value, secondValue]);
    } else {
      setValue(value);
    }
    if (close) {
      setOpen(!close);
    }
  };

  const handleOnChangeMulti = (values: string[]) => {
    setValue(values);
  };

  const removeItem = (index: number) => {
    const val: any = JSON.parse(JSON.stringify(value));

    val.splice(index, 1);

    setValue(val);
  };

  useOutsideClick(toggleContainer, onClickOutsideHandler);

  return (
    <InputStyle
      ref={toggleContainer}
      className={classnames(props.className || "", {
        error: !!props.error,
        disabled: !!props.disabled,
        required: !!props.required,
      })}
    >
      <div className="input-top">
        {props.label && (
          <label className="input-top__label" htmlFor={props.name}>
            <p>
              {props.label}
              {props.required && <span>*</span>}
            </p>
          </label>
        )}
        {/* {props.tooltip && (
           <Tooltip place="left" content={props.tooltip}>
             <SVGIcon icon={icons.question} size={"16px"} /> 
           </Tooltip>
         )} */}
      </div>
      <div className="input-body">
        {/* Add icon-left si el select tiene iconos */}
        {/* Sepamos esto como un drop para meterle tipo select o calendar ??*/}
        <div
          className="input-body-wrapper"
          onClick={() => !props.disabled && handleOpen()}
        >
          {props.icon && (
            <div className="input-body-left-icon input-body-icon">
              <SVGIcon icon={CalendarWhite} />
            </div>
          )}
          <Cleave
            ref={inputRef}
            type="text"
            options={{
              date: true,
              delimiter: "/",
              datePattern: ["d", "m", "Y"],
            }}
            autoComplete={"off"}
            id={props.name}
            value={valueString}
            name={props.name}
            placeholder={props.placeholder}
            disabled={props.disabled}
            required={props.required}
            onChange={(ev: any) =>
              !props.disabled && setValueString(ev.target.value)
            }
            onFocus={(ev: any) =>
              !props.disabled && props.onFocus && props.onFocus(ev)
            }
            onKeyDown={(ev: any) => !props.disabled && handleOnKeyDown(ev)}
          />
          {props.rangeSelect && (
            <Cleave
              id={props.name}
              ref={inputRefRangeSelect}
              type="text"
              autoComplete={"off"}
              options={{
                date: true,
                delimiter: "/",
                datePattern: ["d", "m", "Y"],
              }}
              value={valueString2}
              name={props.nameRangeSelect}
              placeholder={props.placeholderRangeSelect}
              disabled={props.disabled}
              required={props.required}
              onChange={(ev: any) =>
                !props.disabled && setValueString2(ev.target.value)
              }
              onFocus={(ev: any) =>
                !props.disabled &&
                props.onFocusRangeSelect &&
                props.onFocusRangeSelect(ev)
              }
              onKeyDown={(ev: any) =>
                !props.disabled &&
                props.onKeyDownRangeSelect &&
                props.onKeyDownRangeSelect(ev)
              }
            />
          )}

          {props.multiSelect && (
            <div
              className="input-body-icon add"
              onClick={() => handleMultiSelect()}
            >
              {/* <img src="" alt=""/> */}
              {/* iconos tipo pass, alert, validaciones?? */}
            </div>
          )}
          <div className="input-body-icon">
            {/* <img src="" alt=""/> */}
            {/* iconos tipo pass, alert, validaciones?? */}
          </div>
        </div>
        {props.multiSelect && (
          <div className="input-body-dates">
            {value.map((v: string, index: number) => (
              <div
                className="item-date"
                key={v}
                onClick={() => removeItem(index)}
              >
                {v}
              </div>
            ))}
          </div>
        )}
        <InputCalendarStyle
          className={classnames({ open: open || !!props.menuIsOpen })}
        >
          <Calendar
            value={value}
            // tslint:disable-next-line:no-shadowed-variable
            onSelect={(value, close, secondValue) =>
              handleOnSelect(value, close, secondValue)
            }
            forbidFutureDates={props.forbidFutureDates}
            forbidPastDates={props.forbidPastDates}
            rangeSelect={props.rangeSelect}
            minAge={props.minAge}
            maxAge={props.maxAge}
            className={props.classNameCalendar}
            multiSelect={props.multiSelect}
            onChangeMulti={
              props.multiSelect
                ? (values: string[]) => handleOnChangeMulti(values)
                : undefined
            }
          />
        </InputCalendarStyle>
      </div>

      <div className="input-bottom">
        {props.errorMessage && (
          <div className="input-bottom-error">
            <p>{props.errorMessage}</p>
          </div>
        )}
        {props.message && (
          <div className="input-bottom-message">
            <p>{props.message}</p>
          </div>
        )}
      </div>
    </InputStyle>
  );
};

export default InputCalendar;
