import React, { useEffect, useRef } from "react";
import { Close } from "../../assets/icons/icons";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";
import SVGIcon from "../svg-icon/svg-icon";
import { ModalCodeReviewStyle } from "./modal-code-review-style";

interface IReview {
  start?: string;
  end?: string;
  reviewed?: string;
}

export interface IReviews {
  name: string;
  last: IReview;
  actual: IReview;
  next: IReview;
}

interface IModalCodeReviewProps {
  reviews: IReviews;
  onClose: () => void;
  initialPositions: {
    x: number;
    y: number;
  };
}

const ModalCodeReview = (props: IModalCodeReviewProps) => {
  const { reviews, onClose, initialPositions } = props;

  const { isDarkMode } = useDarkMode();
  const containerRef: any = useRef();

  let pos1 = initialPositions.x;
  let pos2 = initialPositions.y;
  let pos3 = 0;
  let pos4 = 0;

  useEffect(() => {
    dragElement(containerRef.current);
    document.body.style.overflowY = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    containerRef.current.style.top = `${initialPositions.y + 20}px`;
    containerRef.current.style.left = `${initialPositions.x - 30}px`;
  }, [initialPositions.x, initialPositions.y]);

  const dragElement = (elmnt: any) => {
    elmnt.onmousedown = dragMouseDown;
  };

  const dragMouseDown = (e: any) => {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const elementDrag = (e: any) => {
    if (containerRef && containerRef.current) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      containerRef.current.style.top = `${
        containerRef.current.offsetTop - pos2
      }px`;
      containerRef.current.style.left = `${
        containerRef.current.offsetLeft - pos1
      }px`;
    }
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const handleClose = () => {
    document.body.style.overflowY = "scroll";
    onClose();
  };

  return (
    <ModalCodeReviewStyle ref={containerRef} onClick={() => handleClose()}>
      <div
        className="modal-code-review-content"
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className="top-close" onClick={() => handleClose()}>
          <SVGIcon
            size="32px"
            icon={Close}
            color={isDarkMode ? "#040404" : "#ffffff"}
          />
        </div>
        <div className="modal-code-review-content__name">
          <p>Code reviews ({reviews.name})</p>
        </div>
        <div className="modal-code-review-content__review">
          <p>
            Anterior:{" "}
            {reviews.last?.start &&
            reviews.last?.end &&
            reviews.last?.reviewed ? (
              <span>
                {reviews.last?.start} hasta {reviews.last?.end} -{" "}
                {reviews.last?.reviewed}
              </span>
            ) : (
              "Sin información"
            )}
          </p>
        </div>
        <div className="modal-code-review-content__review">
          <p>
            Actual:{" "}
            {reviews.actual?.start &&
            reviews.actual?.end &&
            reviews.actual?.reviewed ? (
              <span>
                {reviews.actual?.start} hasta {reviews.actual?.end} -{" "}
                {reviews.actual?.reviewed}
              </span>
            ) : (
              "Sin información"
            )}
          </p>
        </div>
        <div className="modal-code-review-content__review">
          <p>
            Próximo:{" "}
            {reviews.next?.reviewed &&
            reviews.next?.start &&
            reviews.next?.end ? (
              <span>
                {reviews.next?.start} hasta {reviews.next?.end} -{" "}
                {reviews.next?.reviewed}
              </span>
            ) : (
              "Sin información"
            )}
          </p>
        </div>
      </div>
    </ModalCodeReviewStyle>
  );
};

export default ModalCodeReview;
