/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Button from "../../../components/button/button";
import CalendarDetail from "../../../components/calendar-detail/calendar-detail";
import Select from "../../../components/input/select";
import { AIR_TABLE_KEY } from "../../../env";
import { IOptions } from "../../../models/options";
import { isEmpty } from "../../../utils/validations";

const localizer = momentLocalizer(moment);

const HomeCalendar: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [filters, setFilters] = useState<any>({});
  const [offsets, setOffsets] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [employees, setEmployees] = useState<any>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedEventClickPostion, setSelectedEventClickPostion] = useState<
    any
  >({
    x: 0,
    y: 0,
  });
  const [currentDate, setCurrentDate] = useState(moment().toISOString());

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    setPage(0);
    setOffsets([]);
    getWorkLeaves({ filters });
  }, [currentDate, filters]);

  const getWorkLeaves = async (options?: IOptions) => {
    try {
      // Global%20status
      // Peticiones
      const newPage =
        options?.next === undefined ? 0 : options?.next ? page + 1 : page - 1;
      const res: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 100,
            offset: offsets[newPage - 1] || "",
            filterByFormula: getFilter(options?.filters),
          },
        }
      );
      let workLeaves = [];
      workLeaves = res.data.records.map((item: any, index: number) => ({
        title: item.fields["Aluxioner Name"],
        start: moment(item.fields["Día inicio"], "YYYY-MM-DD").add("hour", 12),
        end: moment(item.fields["Día fin"], "YYYY-MM-DD").add("hour", 12),
        raw: item,
        resources: [index],
      }));
      setData(workLeaves);

      if (
        res.data.offset &&
        !offsets.includes(res.data.offset) &&
        options?.next !== false
      ) {
        setOffsets(offsets.concat([res.data.offset]));
      }
      setPage(newPage);

      const calendarBackgroundElements = Array.from(
        document.getElementsByClassName("rbc-day-bg")
      );
      const calendarActiveBackgroundElements: any[] = [];
      calendarBackgroundElements.forEach((elem) => {
        if (elem.className.indexOf("rbc-off-range-bg") < 0) {
          calendarActiveBackgroundElements.push(elem);
        }
      });
      let currentDay = moment(currentDate).startOf("month");
      const daysInMonth = currentDay.daysInMonth();
      for (let i = 0; i < daysInMonth; i += 1) {
        const todayLeaves: any[] = [];
        // eslint-disable-next-line no-loop-func
        workLeaves.forEach((workLeave: any) => {
          if (
            moment(workLeave.start).isSameOrBefore(currentDay, "day") &&
            moment(workLeave.end).isSameOrAfter(currentDay, "day")
          ) {
            todayLeaves.push(workLeave);
          }
        });

        const todayTeams = todayLeaves.map(
          (todayLeave) => todayLeave.raw.fields.Team
        );
        const hasDuplicatedTeams =
          new Set(todayTeams).size !== todayTeams.length;
        if (hasDuplicatedTeams) {
          const currentCalendarElem = calendarActiveBackgroundElements[
            i
          ] as HTMLElement;
          currentCalendarElem.style.background = "rgba(255, 0, 0, 0.13)";
        }
        currentDay = currentDay.add(1, "day");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getEventClassName = (event: any) => {
    switch (event.raw.fields.Team) {
      case "Frontend":
        return "front-team";
      case "Backend":
        return "back-team";
      case "PO/PMs":
        return "management-team";
      case "Design":
        return "design-team";
      case "Marketing":
        return "marketing-team";
      case "RRHH":
        return "rrhh-team";
      case "QA":
        return "qa-team";
      case "Operations":
        return "operations-team";
      default:
        return "operations-team";
    }
  };

  const getFilter = (filtersObj?: any) => {
    const filtersAux = [`{Canceladas?} = FALSE()`];
    if (filtersObj) {
      if (
        filtersObj["Tipo/Motivo"] &&
        !isEmpty(filtersObj["Tipo/Motivo"].value)
      ) {
        filtersAux.push(`{Tipo/Motivo} = '${filtersObj["Tipo/Motivo"].value}'`);
      }
      if (filtersObj.Team && !isEmpty(filtersObj.Team.value)) {
        filtersAux.push(`{Team} = '${filtersObj.Team.value}'`);
      }
      if (
        filtersObj["Aluxioner Name"] &&
        !isEmpty(filtersObj["Aluxioner Name"].value)
      ) {
        filtersAux.push(
          `FIND('${filtersObj["Aluxioner Name"].value}', {Aluxioner Name})`
        );
      }
      if (
        filtersObj["¿Aprobado Manager?"] &&
        !isEmpty(filtersObj["¿Aprobado Manager?"].value)
      ) {
        filtersAux.push(
          `{¿Aprobado Manager?} = ${
            filtersObj["¿Aprobado Manager?"].value ? "TRUE()" : "FALSE()"
          }`
        );
      }
      if (
        filtersObj["¿Aprobado RRHH?"] &&
        !isEmpty(filtersObj["¿Aprobado RRHH?"].value)
      ) {
        filtersAux.push(
          `{¿Aprobado RRHH?} = ${
            filtersObj["¿Aprobado RRHH?"].value ? "TRUE()" : "FALSE()"
          }`
        );
      }
      const initDate = moment(currentDate)
        .startOf("month")
        .add(-1, "days")
        .format("DD/MM/YYYY");
      const finalDate = moment(currentDate)
        .endOf("month")
        .add(1, "days")
        .format("DD/MM/YYYY");
      const initMonth = moment(currentDate)
        .startOf("month")
        .format("DD/MM/YYYY");

      filtersAux.push(
        `OR(AND(
            IS_AFTER({Día inicio}, DATETIME_PARSE('${initDate}',  'DD/MM/YYYY')), 
            IS_BEFORE({Día inicio}, DATETIME_PARSE('${finalDate}', 'DD/MM/YYYY'))
            ), 
            AND(
            IS_AFTER({Día fin}, DATETIME_PARSE('${initDate}',  'DD/MM/YYYY')), 
            IS_BEFORE({Día inicio}, DATETIME_PARSE('${initMonth}', 'DD/MM/YYYY'))
            ))`
      );
    }
    return filtersAux.length > 0 ? `AND(${filtersAux.join(",")})` : "";
  };

  const handleFilters = (name: string, value: any) => {
    filters[name] = value;
    setFilters({ ...filters });
  };

  const getEmployees = async () => {
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Aluxioners",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );

    const res2: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Talent",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );

    const employeesData = [
      {
        value: "",
        label: "Todos",
      },
    ].concat(
      res.data.records
        .map((item: any) => ({
          value: item.fields.Name,
          label: item.fields.Name,
        }))
        .concat(
          res2.data.records.map((item: any) => ({
            value: item.fields.Name,
            label: item.fields.Name,
          }))
        )
    );

    setEmployees(employeesData);
  };

  return (
    <>
      <div className="home-filter-container">
        <div className="home-filter-item">
          <Select
            label={"Nombre"}
            value={filters["Aluxioner Name"]}
            onChange={(option: any) => handleFilters("Aluxioner Name", option)}
            options={employees}
            placeholder="Seleccione Aluxioner"
            customEmpty="Escribe un nombre"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"Team"}
            value={filters.Team}
            onChange={(option: any) => handleFilters("Team", option)}
            options={[
              { className: "all", label: "Todos", value: "" },
              {
                className: "frontend",
                label: "Frontend",
                value: "Frontend",
              },
              {
                className: "backend",
                label: "Backend",
                value: "Backend",
              },
              { className: "po-pm", label: "PO/PMs", value: "PO/PMs" },
              { className: "design", label: "Design", value: "Design" },
              {
                className: "marketing",
                label: "Marketing",
                value: "Marketing",
              },
              { className: "rrhh", label: "RRHH", value: "RRHH" },
              { className: "qa", label: "QA", value: "QA" },
              {
                className: "operations",
                label: "Operations",
                value: "Operations",
              },
            ]}
            placeholder="Seleccione Team"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"Tipo/Motivo"}
            value={filters["Tipo/Motivo"]}
            onChange={(option: any) => handleFilters("Tipo/Motivo", option)}
            options={[
              { label: "Todos", value: "" },
              { label: "Vacaciones", value: "Vacaciones" },
              { label: "Mudanza", value: "Mudanza" },
              { label: "Baja", value: "Baja" },
            ]}
            placeholder="Seleccione Tipo/Motivo"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"¿Aprobado Manager?"}
            value={filters["¿Aprobado Manager?"]}
            onChange={(option: any) =>
              handleFilters("¿Aprobado Manager?", option)
            }
            options={[
              { label: "Todos", value: "" },
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            placeholder="¿Aprobado Manager?"
          />
        </div>
        <div className="home-filter-item">
          <Select
            label={"¿Aprobado RRHH?"}
            value={filters["¿Aprobado RRHH?"]}
            onChange={(option: any) => handleFilters("¿Aprobado RRHH?", option)}
            options={[
              { label: "Todos", value: "" },
              { label: "Si", value: true },
              { label: "No", value: false },
            ]}
            placeholder="¿Aprobado RRHH?"
          />
        </div>
        {/* <div className="home-filter-item">
              <Button type="ghost" text="Buscar" onClick={() => getWorkLeaves({ offset, filters })} />
            </div> */}
        <div className="home-filter-item__button">
          <Button type="ghost" text="Limpiar" onClick={() => setFilters({})} />
        </div>
      </div>
      <div className="dot-circle-container">
        {Object.values(filters).map((filterValue: any) =>
          filterValue.value === "" ? "" : <div className="dot-circle" />
        )}
      </div>
      <Calendar
        views={["month"]}
        localizer={localizer}
        events={data}
        popup={true}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        titleAccessor={(event) => event.title}
        eventPropGetter={(event) => ({
          className: `custom-event
     ${event.raw.fields["¿Aprobado Manager?"] ? "manager-check " : ""}
     ${event.raw.fields["¿Aprobado RRHH?"] ? "rrhh-check " : ""}
     ${getEventClassName(event)}`,
        })}
        onSelectEvent={(event: any, ev: any) => {
          setSelectedEvent(event.raw.fields);
          setSelectedEventClickPostion({
            x: ev.clientX,
            y: ev.clientY,
          });
        }}
        onNavigate={(date) => {
          if (!moment(date).isSame(moment(currentDate), "month")) {
            setCurrentDate(date.toISOString());
          }
        }}
      />
      {selectedEvent && (
        <CalendarDetail
          name={selectedEvent["Aluxioner Name"]}
          requestDate={selectedEvent["Dia de la petición"]}
          startDate={selectedEvent["Día inicio"]}
          finishDate={selectedEvent["Día fin"]}
          poName={selectedEvent["PM/PO"]}
          team={selectedEvent.Team}
          type={selectedEvent["Tipo/Motivo"]}
          managerApproved={selectedEvent["¿Aprobado Manager?"]}
          rrhhApproved={selectedEvent["¿Aprobado RRHH?"]}
          onClose={() => setSelectedEvent(null)}
          initialPositions={selectedEventClickPostion}
        />
      )}
    </>
  );
};

export default HomeCalendar;
