import React, { useRef } from "react";
import { RequestCardStyle } from "./request-card-style";
import { Check } from "../../assets/icons/icons";
import SVGIcon from "../svg-icon/svg-icon";
import useDarkMode from "../../utils/useDarkMode/useDarkMode";

interface IRequestCardProps {
  requestDate: string;
  startDate: string;
  finishDate: string;
  poName: string;
  type: string;
  comment?: string;
  managerApproved: boolean;
  rrhhApproved: boolean;
}

const RequestCard = (props: IRequestCardProps) => {
  const { requestDate, startDate, finishDate, poName, type, managerApproved, rrhhApproved, comment } = props;

  const { isDarkMode } = useDarkMode();
  const containerRef: any = useRef();

  return (
    <RequestCardStyle ref={containerRef}>
      <div className="request-card-content">
        <div className="request-card-content__type">
          <p>
            Tipo:<span>{type}</span>
          </p>
        </div>
        <div className="request-card-content__type">
          <p>
            {" "}
            Pedidas el:<span>{requestDate}</span>
          </p>
        </div>
        <div className="request-card-content__po">
          <p>
            PM/PO: <span>{poName}</span>
          </p>
        </div>
        <div className="request-card-content__duration">
          <div className="request-card-content__duration__text">
            <p>Duración:</p>
          </div>
          <div className="request-card-content__duration__start">
            <p>{startDate}</p>
          </div>
          <div className="request-card-content__duration__space">
            <p>-</p>
          </div>
          <div className="request-card-content__duration__end">
            <p>{finishDate}</p>
          </div>
        </div>
        {comment && (
          <div className="request-card-content__comment">
            <div className="request-card-content__comment__title">{"Comentario:"}</div>
            <div className="request-card-content__comment__text">{comment}</div>
          </div>
        )}
        <div className="request-card-content__status">
          <div className="request-card-content__status__item">
            {managerApproved && (
              <span>
                <SVGIcon icon={Check} color={isDarkMode ? "#040404" : "#ffffff"} size="16px" />
              </span>
            )}
            <p>{managerApproved ? "Aprobado" : "Pendiente"} por Manager</p>
          </div>
          <div className="request-card-content__status__item">
            {rrhhApproved && (
              <span>
                <SVGIcon icon={Check} color={isDarkMode ? "#040404" : "#ffffff"} size="16px" />
              </span>
            )}
            <p>{managerApproved ? "Aprobado" : "Pendiente"} por RRHH</p>
          </div>
        </div>
      </div>
    </RequestCardStyle>
  );
};

export default RequestCard;
