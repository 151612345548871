/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import RequestCard from "../../../components/request-card/request-card";
import { AIR_TABLE_KEY } from "../../../env";
import { IOptions } from "../../../models/options";

interface IHomeMyRequests {
  userEmail: string;
}

const HomeMyRequests: React.FC<IHomeMyRequests> = ({ userEmail }) => {
  const [data, setData] = useState<any>([]);
  const [offsets, setOffsets] = useState<any>([]);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    getWorkLeaves();
  }, []);

  const getWorkLeaves = async (options?: IOptions) => {
    try {
      // Global%20status
      // Peticiones
      const newPage =
        options?.next === undefined ? 0 : options?.next ? page + 1 : page - 1;
      const res: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Peticiones",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 100,
            offset: offsets[newPage - 1] || "",
            filterByFormula: `FIND("${userEmail}", {Aluxioner Name})`,
          },
        }
      );
      let workLeaves = [];
      workLeaves = res.data.records.map((item: any) => ({
        ...item.fields,
        id: item.id,
        "Dia de la petición": moment(
          item.fields["Dia de la petición"],
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY"),
        "Día fin": moment(item.fields["Día fin"], "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        ),
        "Día inicio": moment(item.fields["Día inicio"], "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        ),
      }));

      setData(workLeaves);

      if (
        res.data.offset &&
        !offsets.includes(res.data.offset) &&
        options?.next !== false
      ) {
        setOffsets(offsets.concat([res.data.offset]));
      }
      setPage(newPage);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="my-requests-container">
      {data.length === 0 ? (
        <div>No tienes ninguna petición de vacaciones</div>
      ) : (
        <div className="my-requests-container__grid">
          {data.map((requestLeave: any) => (
            <>
              <RequestCard
                requestDate={requestLeave["Dia de la petición"]}
                startDate={requestLeave["Día inicio"]}
                finishDate={requestLeave["Día fin"]}
                poName={requestLeave["PM/PO"]}
                type={requestLeave["Tipo/Motivo"]}
                managerApproved={!!requestLeave["¿Aprobado Manager?"]}
                rrhhApproved={!!requestLeave["¿Aprobado RRHH?"]}
                comment={requestLeave["Comentario/Descripción"]}
              />
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default HomeMyRequests;
