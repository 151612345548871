/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import TrackerDetail from "../../../components/tracker-detail/tracker-detail";
import { AIR_TABLE_KEY } from "../../../env";
import { IOptions } from "../../../models/options";

interface ITrackerCalendar {
  userEmail: string;
}

const localizer = momentLocalizer(moment);

const TrackerCalendar: React.FC<ITrackerCalendar> = ({ userEmail }) => {
  const [data, setData] = useState<any>([]);
  const [projectColors, setProjectColors] = useState<any>({});
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedEventClickPostion, setSelectedEventClickPostion] = useState<
    any
  >({
    x: 0,
    y: 0,
  });
  const [currentDate, setCurrentDate] = useState(moment().toISOString());
  const [page, setPage] = useState<number>(0);
  const [offsets, setOffsets] = useState<any>([]);

  const colors = [
    "#3c96b4",
    "#3aa537",
    "#8c5ac8",
    "#ffd048",
    "#e5546c",
    "#faa082",
    "#000000",
    "#e677d7",
    "#fdeab6",
    "#cccccc",
    "#d74d2f",
    "#b8762d",
    "#ba2c45",
    "#2e7ff6",
    "#44a09c",
    "#4bbff8",
  ];

  useEffect(() => {
    getTrackers();
    return () => {
      setOffsets([]);
      setData([]);
      setPage(0);
    };
  }, []);

  useEffect(() => {
    setPage(0);
    setOffsets([]);
    getTrackers();
  }, [currentDate]);

  const getTrackers = async (options?: IOptions) => {
    const newPage =
      options?.next === undefined ? 0 : options?.next ? page + 1 : page - 1;
    const initDate = moment(currentDate)
      .startOf("month")
      .add(-1, "days")
      .format("DD/MM/YYYY");
    const finalDate = moment(currentDate)
      .endOf("month")
      .add(1, "days")
      .format("DD/MM/YYYY");
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appHXEAplyyE7zksw/Table%201",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
          offset: offsets[newPage - 1] || "",
          filterByFormula: `AND(IS_AFTER({Fecha Hour Tracker}, DATETIME_PARSE('${initDate}',  'DD/MM/YYYY')),IS_BEFORE({Fecha Hour Tracker}, DATETIME_PARSE('${finalDate}', 'DD/MM/YYYY')),FIND('${userEmail}', {Aluxioner}))`,
        },
      }
    );

    let auxProjectColors = { ...projectColors };
    res.data.records.forEach((tracker: any) => {
      if (!Object.keys(auxProjectColors).includes(tracker.fields.Proyecto)) {
        const color = colors.find(
          (colorAux: string) =>
            !Object.values(auxProjectColors).includes(colorAux)
        );
        auxProjectColors = {
          ...auxProjectColors,
          [tracker.fields.Proyecto]: color,
        };
      }
    });

    setProjectColors(auxProjectColors);

    let trackersMapped = [];
    trackersMapped = res.data.records.map((item: any, index: number) => ({
      title: `${item.fields.Proyecto} - ${item.fields.Horas} horas`,
      start: moment(item.fields["Fecha Hour Tracker"], "YYYY-MM-DD").add(
        "hour",
        12
      ),
      end: moment(item.fields["Fecha Hour Tracker"], "YYYY-MM-DD").add(
        "hour",
        12
      ),
      raw: item,
      resources: [index],
    }));

    setData(trackersMapped);
  };

  return (
    <>
      <Calendar
        views={["month"]}
        localizer={localizer}
        events={data}
        popup={true}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        titleAccessor={(event) => event.title}
        eventPropGetter={(event) => ({
          className: `custom-event
       ${event.raw.fields["¿Aprobado Manager?"] ? "manager-check " : ""}
       ${event.raw.fields["¿Aprobado RRHH?"] ? "rrhh-check " : ""}`,
          style: {
            background: projectColors[event.raw.fields.Proyecto],
          },
        })}
        onSelectEvent={(event: any, ev: any) => {
          setSelectedEvent(event.raw.fields);
          setSelectedEventClickPostion({
            x: ev.clientX,
            y: ev.clientY,
          });
        }}
        onNavigate={(date) => {
          if (!moment(date).isSame(moment(currentDate), "month")) {
            setCurrentDate(date.toISOString());
          }
        }}
      />
      {selectedEvent && (
        <TrackerDetail
          name={selectedEvent.Aluxioner}
          date={selectedEvent["Fecha Hour Tracker"]}
          project={selectedEvent.Proyecto}
          task={selectedEvent["Tareas realizadas"]}
          hours={selectedEvent.Horas}
          blockers={selectedEvent.Blockers}
          onClose={() => setSelectedEvent(null)}
          initialPositions={selectedEventClickPostion}
        />
      )}
    </>
  );
};

export default TrackerCalendar;
