import axios from "axios";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { AIR_TABLE_KEY } from "../../env";
import TrackerAdminAluxionersCalendar from "./components/tracker-admin-aluxioners-calendar";
import TrackerAdminTable from "./components/tracker-admin-table";
import TrackerCalendar from "./components/tracker-calendar";
import { TrackerStyle } from "./tracker-style";

interface ITrackerProps {
  userEmail: string;
}

const Tracker = (props: ITrackerProps) => {
  const { userEmail } = props;
  const [section, setSection] = useState<
    "table" | "calendar" | "calendar-aluxioners"
  >("calendar");

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkIfAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const checkIfAdmin = async () => {
    const res: any = await axios.get(
      "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Admins",
      {
        params: {
          api_key: AIR_TABLE_KEY,
          pageSize: 100,
        },
      }
    );
    const isAdminResp =
      res.data.records.filter((rec: any) => rec.fields.Email === userEmail)
        .length > 0;
    setIsAdmin(isAdminResp);
  };

  const handleChangeSection = (
    sec: "table" | "calendar" | "calendar-aluxioners"
  ) => {
    setSection(sec);
  };

  return (
    <>
      <TrackerStyle>
        <div className="tracker-nav">
          <div className="tracker-nav-left">
            <div
              className={`tracker-nav-tab ${
                section === "calendar" ? "active" : ""
              }`}
              onClick={() => handleChangeSection("calendar")}
            >
              Calendar
            </div>
            {isAdmin && (
              <>
                <div
                  className={`tracker-nav-tab ${
                    section === "calendar-aluxioners" ? "active" : ""
                  }`}
                  onClick={() => handleChangeSection("calendar-aluxioners")}
                >
                  Calendar Aluxioners
                </div>
                <div
                  className={`tracker-nav-tab ${
                    section === "table" ? "active" : ""
                  }`}
                  onClick={() => handleChangeSection("table")}
                >
                  Tabla Admin
                </div>
              </>
            )}
          </div>
        </div>

        <div className="tracker-container">
          {section === "calendar" && <TrackerCalendar userEmail={userEmail} />}
          {section === "calendar-aluxioners" && isAdmin && (
            <TrackerAdminAluxionersCalendar />
          )}
          {section === "table" && isAdmin && (
            <TrackerAdminTable userEmail={userEmail} />
          )}
        </div>
      </TrackerStyle>
    </>
  );
};

export default Tracker;
