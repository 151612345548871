import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

interface ISVGIcon {
  icon: string;
  color?: string;
  size?: "16px" | "24px" | "32px" | "48px" | "64px" | string;
}

interface ISVGIconStyle {
  color?: string;
  size?: "16px" | "24px" | "32px" | "48px" | "64px" | string;
}

const SVGIconStyle = styled.div<ISVGIconStyle>`
  svg {
    width: 100%;
    height: auto;
  }
  ${(props) =>
    props.color &&
    css`
      path,
      line {
        fill: ${props.color};
        stroke: ${props.color};
      }
    `}
  ${(props) =>
    props.size &&
    css`
      width: ${props.size};
      min-width: ${props.size};
      svg {
        width: ${props.size};
        min-width: ${props.size};
        height: auto;
      }
    `}
`;

const SVGIcon: React.FC<ISVGIcon> = (props: ISVGIcon) => {
  const { icon, color, size } = props;
  return (
    <SVGIconStyle color={color} size={size}>
      <ReactSVG src={icon} />
    </SVGIconStyle>
  );
};

export default SVGIcon;
