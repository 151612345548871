import styled from "styled-components";

export const ButtonStyle = styled.div`
  cursor: pointer;
  width: 100%;
  min-height: 44px;
  padding: 12px;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out all;
  .button-text {
    flex: auto;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  .button-right-icon,
  .button-left-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  .button-left-count {
    border: 1px solid ${(props) => props.theme.bn.white};
    color: #2251ff;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    background: ${(props) => props.theme.bn.white};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .button-left-icon {
    margin-right: 8px;
  }
  .button-right-icon {
    margin-left: 8px;
  }
  /* Size */

  &.large {
    padding: 12px 8px 14px;
    min-height: 44px;
    .button-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
    }
  }
  &.medium {
    padding: 8px 8px 10px;
    min-height: 40px;
    .button-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &.small {
    padding: 6px 8px 7px;
    min-height: 32px;
    .button-text {
      font-size: 14px;
      line-height: 16px;
    }
  }

  /*  */

  &.primary {
    color: ${(props) => props.theme.bn.white};
    background: ${(props) => props.theme.bn.black};
    border: 1px solid ${(props) => props.theme.bn.white};
    &:hover {
      color: ${(props) => props.theme.bn.black};
      border: 1px solid ${(props) => props.theme.bn.white};
      background: ${(props) => props.theme.bn.white};
    }
    &:active  {
      color: ${(props) => props.theme.bn.black};
      border: 1px solid ${(props) => props.theme.bn.white};
      background: ${(props) => props.theme.bn.white};
    }
    svg {
      path {
        fill: ${(props) => props.theme.bn.white};
      }
    }
  }

  &.danger {
    color: #f25e5e;
    background: #fff;
    border: 2px solid #f25e5e;
    &:hover {
      background: #f25e5e;
      color: #fff;
    }
    svg {
      path {
        fill: ${(props) => props.theme.bn.white};
      }
    }
  }

  &.secondary {
    border: 1px solid ${(props) => props.theme.bn.black};
    background: ${(props) => props.theme.bn.white};
    color: ${(props) => props.theme.bn.black};
    &:hover {
      background: ${(props) => props.theme.bn.white};
      border: 1px solid ${(props) => props.theme.bn.black};
    }
    &:active  {
      background: ${(props) => props.theme.bn.white};
      border: 1px solid ${(props) => props.theme.bn.black};
    }
    svg {
      path {
        fill: ${(props) => props.theme.bn.black};
      }
    }
  }

  &.ghost {
    border: 1px solid ${(props) => props.theme.bn.gray_70};
    background: none;
    color: ${(props) => props.theme.bn.white};
    &:hover {
      background: none;
      border: 1px solid ${(props) => props.theme.bn.white};
    }
    &:active  {
      background: none;
      border: 1px solid ${(props) => props.theme.bn.white};
    }
    svg {
      path {
        fill: ${(props) => props.theme.bn.white};
      }
    }
  }

  /*  */

  &.disabled {
    background: ${(props) => props.theme.bn.gray_70};
    border: 0px solid ${(props) => props.theme.bn.gray_70};
    color: ${(props) => props.theme.bn.gray_30};
    pointer-events: none;
  }
  &.error {
    border: 1px solid #ff0000;
  }

  &.filter {
    color: ${(props) => props.theme.bn.white};
    background: #2251ff;
    padding: 12px 16px 14px;
    &:hover {
      background: ${(props) => props.theme.bn.black};
    }
    &:active  {
      background: #0028bd;
    }
    svg {
      path {
        fill: ${(props) => props.theme.bn.white};
      }
    }
  }
  &.filter-active {
    color: ${(props) => props.theme.bn.white};
    background: #0028bd;
    padding: 12px 16px 14px;

    &:active  {
      background: #0028bd;
    }
    svg {
      path {
        fill: ${(props) => props.theme.bn.white};
      }
    }
  }

  /*  */
  &.in-group {
    border-right: 1px solid none;
  }
`;

export const ButtonIconStyle = styled(ButtonStyle)`
  padding: 8px;
  width: fit-content;
  border: 1px solid black;
  .button-icon {
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
