// tslint:disable:no-var-requires
import { createGlobalStyle } from "styled-components";
const Fonts = createGlobalStyle`

@font-face {
  font-family: "hall";
  src: url(${require("./files/hall-of-fun.ttf")});
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "orpheus";
  src: url(${require("./files/orpheus_pro.otf")});
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "orpheus";
  src: url(${require("./files/orpheus_pro-medium.otf")});
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "norms";
  src: url(${require("./files/tt_norms_light.otf")});
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "norms";
  src: url(${require("./files/tt_norms_regular.otf")});
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "norms";
  src: url(${require("./files/tt_norms_medium.otf")});
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "norms";
  src: url(${require("./files/tt_norms_bold.otf")});
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
  }
@font-face {
  font-family: "norms";
  src: url(${require("./files/tt_norms_extrabold.otf")});
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  }

`;

export default Fonts;
