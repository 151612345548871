import styled from "styled-components";

export const colors: any = {
  bn: {
    gray_80: "#333333",
    gray_70: "#4D4D4D",
    gray_50: "#7F7F7F",
    gray_30: "#B3B3B3",
    white: "#FFFFFF",
  },
  ui: {
    deep_electric_blue: "#2251FF",
  },
};

export const CheckboxStyle = styled.div`
  .checkbox-container {
    display: flex;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:hover {
      .checkbox-item {
        border: 1px solid ${colors.bn.gray_50};
      }
      .checkbox-label {
        color: ${colors.bn.gray_50};
      }
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;

    /* Tambien se define en active */
    &:checked ~ .checkbox-item {
      background: #000000;
    }
  }

  .checkbox-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 2px 0;
    margin-right: 8px;
    padding: 3px;
    border: 1px solid ${colors.bn.gray_50};
    transition: 0.3s ease-in-out all;
  }

  .checkbox-label {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    transition: 0.3s ease-in-out all;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    .checkbox-item {
      border: 1px solid ${colors.bn.gray_50};
      background: ${colors.bn.gray_18};
    }
  }

  &.active {
    .checkbox-container {
      .checkbox-item {
        border: 1px solid ${colors.bn.gray_50};
        background: ${colors.bn.gray_50};
      }
    }
  }

  &.error {
    .checkbox-label {
      color: #ff0000;
    }

    .checkbox-item {
      border: 1px solid #ff0000;
    }
  }

  &.radio {
    .checkbox-item {
      border-radius: 100%;
      &__circle  {
        width: 100%;
        background: ${colors.bn.gray_50};
        height: 100%;
        border-radius: 100%;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-in-out all;
      }
    }
    &.active {
      .checkbox-container {
        .checkbox-item {
          border: 1px solid ${colors.bn.gray_50};
          background: none;
          &__circle {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &.indeterminated {
    .checkbox-container {
      .checkbox-item {
        border: 1px solid ${colors.bn.gray_50};
        background: red;
      }
    }
    &.active {
      .checkbox-container {
        .checkbox-item {
          border: 1px solid ${colors.bn.gray_50};
          background: ${colors.bn.gray_50};
        }
      }
    }
  }
`;
