import React from "react";
import { IndeterminateCheckbox } from "../indeterminate-checkbox";
import { Radio } from "../input-radio";
export const useSelection = (hooks: any) => {
  hooks.useOptions.push((options: any) => {
    if (options.multipleSelection) {
      hooks.columns.push((column: any) => [
        {
          id: "multiple-selection",
          Header: (params: any) => (
            <IndeterminateCheckbox
              {...params.getToggleAllRowsSelectedProps()}
            />
          ),
          Cell: (params: any) => (
            <IndeterminateCheckbox
              {...params.row.getToggleRowSelectedProps()}
            />
          )
        },
        ...column
      ]);
      return [options];
    }
    if (options.selection) {
      hooks.columns.push((column: any) => [
        {
          id: "selection",
          Header: () => null,
          Cell: (cell: any) => {
            const { checked } = cell.row.getToggleRowSelectedProps();
            return (
              <Radio
                name={cell.row.original.id}
                onCheck={options.onRadioCheck}
                checked={checked}
              />
            );
          }
        },
        ...column
      ]);
      return [options];
    }
    return [options];
  });
};
