import { useState } from "react";

import { IUserDarkMode } from "./useDarkMode-type";

const useDarkMode: IUserDarkMode = () => {
  const stored = localStorage.getItem("isDarkMode");
  const [isDarkMode, setIsDarkMode] = useState(stored === "true" ? true : false);

  const setMode = (value: string) => {
    localStorage.setItem("isDarkMode", value);
    window.location.reload();
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    setMode((!isDarkMode).toString());
  };

  const setDarkMode = () => {
    setIsDarkMode(true);
    setMode("true");
  };

  const setNormalMode = () => {
    setIsDarkMode(false);
    setMode("false");
  };

  return {
    isDarkMode,
    toggleDarkMode,
    setDarkMode,
    setNormalMode,
  };
};

export default useDarkMode;
