import React from 'react';


export const Radio: React.FC<any> = ({
  name,
  onCheck,
  checked
}) => {
  const handleChange = () => {
    if (onCheck) {
      onCheck(name);
    }
  };

  return (

      <div className="checkbox-wrapper">
        <label className="checkbox-container">
          <input
            type='checkbox'
            name={name}
            checked={checked}
            onChange={() => handleChange()}
          />
          <div className="checkmark-container">
            <div className="checkmark-item" />
          </div>
        </label>
      </div>

  );
};
