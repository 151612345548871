import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { darkTheme } from "./assets/colors/darkTheme";
import { lightTheme } from "./assets/colors/lightTheme";
import Fonts from "./assets/fonts/fonts";
import Header from "./components/header/header";
import ModalLogin from "./components/modal-login/modal-login";
import ZeroContent from "./components/zero-content/zero-content";
import Home from "./containers/home/home";
import Status from "./containers/status/status";
import Tracker from "./containers/tracker/tracker";
import ResetStyles from "./utils/reset";
import useDarkMode from "./utils/useDarkMode/useDarkMode";

const App = () => {
  const { isDarkMode } = useDarkMode();
  const [userEmail, setUserEmail] = useState("");

  return (
    <>
      <Fonts />
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          renderTrackHorizontal={(props) => (
            <div {...props} className="track-horizontal" />
          )}
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          renderThumbHorizontal={(props) => (
            <div {...props} className="thumb-horizontal" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          style={{ width: "100%", height: "100vh" }}
        >
          <ResetStyles />
          <Router>
            <Header />
            <Switch>
              <Route path="/status" component={Status} />
              <Route
                path="/tracker"
                component={() => <Tracker userEmail={userEmail} />}
              />
              <Route
                exact
                path="/"
                component={() => <Home userEmail={userEmail} />}
              />
              <Route path="/" component={ZeroContent} />
            </Switch>
          </Router>
          <ModalLogin onUserEmail={(email) => setUserEmail(email)} />
        </Scrollbars>
      </ThemeProvider>
    </>
  );
};

export default App;
