import axios from 'axios'

export const sendMail = async (args: any) => {
    // args.setLoading(args.loader);
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
        const response = await axios({
            headers,
            method: 'POST',
            url: `https://aluxionfunctions.azurewebsites.net/api/AluxionMail?code=Qoi2olAXLLEYHEW6BVf9uHXZgbZFYchntWEayBPjrPpRvfpm/VcCTQ==`,
            data: args,
        })
        // args.setLoading(false);
        return response
    } catch (err) {
        // args.setLoading(false);
        throw err
    }
}
