import styled from "styled-components";

export const ZeroContentStyle = styled.div`
  width: 100%;
  height: 100%;
  min-height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.bn.black};
  padding: 40px;

  .zero-content-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
  .zero-content-text {
    text-align: center;
    max-width: 898px;
    a {
      text-decoration: underline;
    }
  }
  .zero-content-button {
    margin-top: 12px;
  }

  &.auto-height {
    min-height: unset;
  }
`;
