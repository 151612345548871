import axios from "axios";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactTooltip from "react-tooltip";
import { Info } from "../../assets/icons/icons";
import SVGIcon from "../../components/svg-icon/svg-icon";
import { AIR_TABLE_KEY } from "../../env";
import { IOptions } from "../../models/options";
import { StatusStyle } from "./status-style";

const Status = () => {
  const [data, setData] = useState<any>(null);
  const [filters] = useState<any>({});
  const [offsets, setOffsets] = useState<any>([]);

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Name",
  //       accessor: "Name",
  //       sizeCol: 8,
  //     },
  //     {
  //       Header: "Días disponibles",
  //       accessor: "Días disponibles",
  //       sizeCol: 2,
  //     },
  //     {
  //       Header: "Días usados",
  //       accessor: "Días usados",
  //       sizeCol: 2,
  //     },
  //     {
  //       Header: "Días totales",
  //       accessor: "Días totales",
  //       sizeCol: 2,
  //     },
  //   ],
  //   []
  // );

  useEffect(() => {
    getEmployees({ filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("loggedEmail")]);

  useEffect(() => {
    if (data) {
      ReactTooltip.rebuild();
    }
  }, [data]);

  const getEmployees = async (options?: IOptions) => {
    try {
      // Global%20status
      // Peticiones
      const res: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Aluxioners",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 10,
            offset: options?.offset,
            filterByFormula: `{Email} = '${localStorage.getItem(
              "loggedEmail"
            )}'`,
          },
        }
      );

      const res2: any = await axios.get(
        "https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Talent",
        {
          params: {
            api_key: AIR_TABLE_KEY,
            pageSize: 10,
            offset: options?.offset,
            filterByFormula: `{Email} = '${localStorage.getItem(
              "loggedEmail"
            )}'`,
          },
        }
      );

      const newData = (res.data.records.length > 0
        ? res.data.records
        : res2.data.records
      ).map((item: any) => ({
        ...item.fields,
      }));

      setData(newData[0]);
      setOffsets([...offsets, res.data.offset]);
    } catch (err) {
      console.log(err);
    }
  };

  // const getFilter = (filtersObj?: any) => {
  //   if (filtersObj) {
  //     const filtersAux = [];
  //     if (filtersObj.Name && !isEmpty(filtersObj.Name.value)) {
  //       filtersAux.push(`{Name} = '${filtersObj.Name.value}'`);
  //     }

  //     return filtersAux.length > 0 ? `AND(${filtersAux.join(",")})` : "";
  //   }
  //   return "";
  // };

  // const handlePage = (newPage: number, newOffset: string) => {
  //   getEmployees({ offset: newOffset });
  //   setOffset(newOffset);
  //   setPage(newPage);
  // };

  // const handleFilters = (name: string, value: any) => {
  //   filters[name] = value;
  //   setFilters({ ...filters });
  // };

  // let searchWaiter: any = null;
  // const handleEmployees = async (value: string) => {
  //   if (searchWaiter) {
  //     clearInterval(searchWaiter);
  //   }
  //   try {
  //     searchWaiter = setTimeout(async () => {
  //       const res: any = await axios.get("https://api.airtable.com/v0/appNIeE8Lj55AIrXc/Global%20status", {
  //         params: {
  //           api_key: AIR_TABLE_KEY,
  //           filterByFormula: value ? `SEARCH("${value}", Name) > 0` : "",
  //         },
  //       });
  //       const employeesData = res.data.records.map((item: any) => ({
  //         value: item.fields.Name,
  //         label: item.fields.Name,
  //       }));
  //       setEmployees(employeesData);
  //     }, 750);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <>
      {/* <div className="status-nav">
          <div className="status-nav-left">
            <div className="status-nav-filter">
              <Select
                value={filters.Name}
                onChange={(option: any) => handleFilters("Name", option)}
                onChangeInput={(ev: any) => handleEmployees(ev.target.value)}
                options={employees}
                placeholder="Seleccione Aluxioner"
              />
            </div>
          </div>
          <div className="status-nav-right">
             <div className="status-nav-filter">
              <Select
                value={filters.Team}
                onChange={(option: any) => handleFilters("Team", option)}
                options={[
                  { label: "Todos", value: "" },
                  { label: "Frontend", value: "Frontend" },
                  { label: "Backend", value: "Backend" },
                  { label: "PO/PMs", value: "PO/PMs" },
                  { label: "Design", value: "Design" },
                  { label: "Marketing", value: "Marketing" },
                  { label: "RRHH", value: "RRHH" },
                  { label: "QA", value: "QA" },
                  { label: "Operations", value: "Operations" },
                ]}
                placeholder="Seleccione Team"
              />
            </div> 
            <div className="status-nav-button">
              <Button type="secondary" text="Buscar" onClick={() => getEmployees({ offset, filters })} />
            </div>
            <div className="status-nav-button">
              <Button type="secondary" text="Limpiar" onClick={() => setFilters({})} />
            </div>
          </div>
        </div> */}
      <ReactTooltip />
      <StatusStyle>
        <div className="status-container">
          <>
            <div className="status-card">
              <div className="status-card-title">
                {`Hola ${
                  data && data.Name
                } 👋🏼, aquí tienes la info de tus vacaciones:`}
              </div>
              <div className="status-card-body">
                <div className="status-card-body__title">
                  <p>{"Dias usados"}</p>
                </div>
                <div className="status-card-body__field">
                  <p>{data && data["Dias usados"]}</p>
                </div>
                <div
                  data-effect="solid"
                  data-tip="Días libres gastados o futuros ya aprobados."
                  className="status-card-body__tooltip"
                >
                  <SVGIcon icon={Info} color={"black"} size="20px" />
                </div>
                <div className="status-card-body__title">
                  <p>{"Dias totales"}</p>
                </div>
                <div className="status-card-body__field">
                  <p>{data && data["Dias totales"]}</p>
                </div>
                <div
                  data-effect="solid"
                  data-tip="Días totales incluyendo los gastados."
                  className="status-card-body__tooltip"
                >
                  <SVGIcon icon={Info} color={"black"} size="20px" />
                </div>
                <div className="status-card-body__title">
                  <p>{"Dias disponibles totales"}</p>
                </div>
                <div className="status-card-body__field">
                  <p>{data && data["Dias disponibles totales"]}</p>
                </div>
                <div
                  data-effect="solid"
                  data-tip="Días disponibles hasta final de año."
                  className="status-card-body__tooltip"
                >
                  <SVGIcon icon={Info} color={"black"} size="20px" />
                </div>
                <div className="status-card-body__title">
                  <p>{"Dias disponibles hoy"}</p>
                </div>
                <div className="status-card-body__field">
                  <p>{data && data["Dias disponibles hoy"]}</p>
                </div>
                <div
                  data-effect="solid"
                  data-tip="Días acumulados desde principio de año o contrato"
                  className="status-card-body__tooltip"
                >
                  <SVGIcon icon={Info} color={"black"} size="20px" />
                </div>
                {data && !!data["Año Pasado"] && (
                  <>
                    <div className="status-card-body__title">
                      <p>{"Días del año pasado"}</p>
                    </div>
                    <div className="status-card-body__field">
                      <p>{data && data["Año Pasado"]}</p>
                    </div>
                    <div
                      data-effect="solid"
                      data-tip="Días restante del año pasado que hay consumir antes de finalizar marzo"
                      className="status-card-body__tooltip"
                    >
                      <SVGIcon icon={Info} color={"black"} size="20px" />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <MainTable data={data} columns={columns} /> */}
            {/* <div className="table-buttons">
              <div className="table-buttons__item">
                <Button
                  size="small"
                  type="secondary"
                  text="Siguiente"
                  onClick={() => offsets[page] && handlePage(page + 1, offsets[page])}
                />
              </div>
              <div className="table-buttons__item">
                <Button
                  size="small"
                  type="secondary"
                  text="Anterior"
                  onClick={() => page !== 0 && handlePage(page - 1, page - 1 === 0 ? "" : offsets[page - 1])}
                />
              </div>
            </div> */}
          </>
        </div>
      </StatusStyle>
    </>
  );
};

export default Status;
