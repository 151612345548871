import styled from "styled-components";

export const HeaderStyle = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 32px 60px;
  display: flex;
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.bn.black};

  .header-left {
    display: flex;
    align-items: center;
    &__title {
      margin-left: 24px;
      padding-left: 24px;
      font-weight: 400;
      font-size: 28px;
      font-family: "hall";
      border-left: 1px solid ${(props) => props.theme.bn.gray_50};
    }
  }
  .header-right {
    display: flex;

    .header-right-nav {
      display: flex;
      align-items: center;
      &__item {
        cursor: pointer;
        padding: 8px 12px;
        a {
          &.active {
            border-bottom: 1px solid ${(props) => props.theme.bn.white};
          }
        }
      }
      &__request {
        margin-left: 12px;
      }
      &__theme {
        margin-left: 24px;
        padding-left: 24px;
        border-left: 1px solid ${(props) => props.theme.bn.gray_50};
        cursor: pointer;
      }
    }
  }
`;
