import React from "react";

import { PaginationContainer } from "./pagination-style";
import Button from "../button/button";

interface PaginationProps {
  hasPrevious: boolean;
  hasNext: boolean;
  handlePrevious: any;
  handleNext: any;
  limit: number;
  page: number;
  goTo: (page: number) => void;
  onClick?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  hasPrevious,
  hasNext,
  page,
  handleNext,
  handlePrevious,
  limit,
  goTo,
  onClick
}) => {
  const currentPage = page + 1;

  return (
    <PaginationContainer>
      <div className="pagination-container">
        {hasPrevious && (
          <div className="pagination-left">
            <Button
            size="small"
              type="primary"
              onClick={() => {
                handlePrevious();
                if (onClick) {
                  onClick(currentPage - 1);
                }
              }}
              className={"limit"}
              text="Anterior"
            />
          </div>
        )}

        <div className="pagination-main">
          {currentPage > 1 && (
            <div className="pagination-main__item">
              <Button
              size="small"
                type="secondary"
                text="1"
                onClick={() => {
                  goTo(0);
                  if (onClick) {
                    onClick(1);
                  }
                }}
              />
            </div>
          )}
          {currentPage > 3 && (
            <div className="pagination-main__item">
              <Button
                onClick={() => {
                  "";
                }}
                disabled
                className="disabled"
                size="small"
                type="secondary"
                text="..."
              />
            </div>
          )}
          {currentPage > 2 && (
            <div className="pagination-main__item">
              <Button
              size="small"
                type="secondary"
                onClick={() => {
                  goTo(page - 1);
                  if (onClick) {
                    onClick(currentPage - 1);
                  }
                }}
                text={(currentPage - 1).toString()}
              />
            </div>
          )}

          <div className="pagination-main__item">
            <Button
              onClick={() => {
                "";
              }}
              size="small"
              type="secondary"
              className="actual"
              text={currentPage.toString()}
            />
          </div>

          {currentPage + 1 < limit && (
            <div className="pagination-main__item">
              <Button
              size="small"
                type="secondary"
                onClick={() => {
                  goTo(page + 1);
                  if (onClick) {
                    onClick(currentPage + 1);
                  }
                }}
                text={(currentPage + 1).toString()}
              />
            </div>
          )}
          {currentPage + 2 < limit && (
            <div className="pagination-main__item">
              <Button
                disabled
                onClick={() => {
                  "";
                }}
                className="disabled"
                size="small"
                type="danger"
                text="..."
              />
            </div>
          )}
          {currentPage < limit && (
            <div className="pagination-main__item">
              <Button
              size="small"
                type="secondary"
                onClick={() => {
                  goTo(limit - 1);
                  if (onClick) {
                    onClick(limit);
                  }
                }}
                text={limit.toString()}
              />
            </div>
          )}
        </div>

        {hasNext && (
          <div className="pagination-right">
            <Button
            size="small"
              type="primary"
              onClick={() => {
                handleNext();
                if (onClick) {
                  onClick(currentPage + 1);
                }
              }}
              className={"limit"}
              text="Siguiente"
            />
          </div>
        )}
      </div>
    </PaginationContainer>
  );
};

export default Pagination;
